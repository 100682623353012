import React, { useState, useEffect } from 'react'
import { baseUrl } from '../lib/_processUtil'
import ImageWithDimensions from './ImageWithDimensions'
import { preventScrollingOnOpen } from '../lib/methodsUtil'
import { createBorderRadius } from '../lib/methodsUtil'

const Modal = ({ activatorButtonText, activatorButtonClass, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  // Close if escape is keydowned
  const onKeyDown = event => {
    if (event.keyCode === 27) {
      setIsOpen(false)
      preventScrollingOnOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  preventScrollingOnOpen(isOpen)

  return (
    <>
      <div className={`with-transition ${isOpen ? 'with-transition-opened' : ''}`}>
        <div className="modal-mask" />
        <div className="modal-wrapper">
          <div className="modal-section-wrapper" style={createBorderRadius(0.26)}>
            <section className="modal-section">
              {children}
              <ImageWithDimensions
                alt="close icon"
                className="modal-close-arrow"
                src={`${baseUrl}/images/icons/close.webp`}
                onClick={() => {
                  setIsOpen(false)
                }}
              />
            </section>
          </div>
        </div>
      </div>

      <button
        onClick={() => setIsOpen(true)}
        className={activatorButtonClass}
        style={createBorderRadius(0.46)}>
        {activatorButtonText}
      </button>
    </>
  )
}

export default Modal
