import actions from '../actions/action-types'
import { createInitialWinningImagesList } from '../../lib/themeUtil'
import { getLngCode } from '../../lib/languagesUtils/languageUtil'
import { getRandomItemFromList } from '../../lib/methodsUtil'

const initialState = {
  isAnnyangSupported: false,
  isUsingMicrophone: false,
  isChrome: true,
  isListening: false,
  path: '/',
  transcript: [],
  language: 'en-GB',
  lngCode: 'en',
  gender: 'man',
  soundLevel: 'on',
  temporarilyUnlocked: false,
  isCorrectAnswer: false,
  isPlaying: false,
  winningImagesList: createInitialWinningImagesList(),
  winningImage: null,
  isRoboticVoiceNoticeVisible: false,
  notification: null
}

let winningImagesList
let winningImage

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.updateIsAnnyangSupported:
      return {
        ...state,
        isAnnyangSupported: action.payload
      }

    case actions.updateIsUsingMicrophone:
      return {
        ...state,
        isUsingMicrophone: action.payload
      }

    case actions.updateIsChrome:
      return {
        ...state,
        isChrome: action.payload
      }

    case actions.updateIsListening:
      return {
        ...state,
        isListening: action.payload
      }

    case actions.changePath:
      return {
        ...state,
        path: action.payload,
        transcript: []
      }

    case actions.saveTranscript:
      return {
        ...state,
        transcript: action.payload
      }

    case actions.updateLanguage:
      return {
        ...state,
        language: action.payload,
        lngCode: getLngCode(action.payload)
      }

    case actions.updateGender:
      return {
        ...state,
        transcript: [],
        gender: action.payload
      }

    case actions.updateSoundLevel:
      return {
        ...state,
        transcript: [],
        soundLevel: action.payload
      }

    case actions.updateTemporarilyUnlocked:
      return {
        ...state,
        temporarilyUnlocked: action.payload
      }

    case actions.updateNotification:
      return {
        ...state,
        notification: action.payload
      }

    case actions.clearNotification:
      return {
        ...state,
        notification: null
      }

    case actions.isCorrectAnswer:
      return {
        ...state,
        isCorrectAnswer: true
      }

    case actions.isPlaying:
      return {
        ...state,
        isPlaying: action.payload
      }

    case actions.resetForNextQuestion:
      return {
        ...state,
        transcript: [],
        isCorrectAnswer: false
      }

    case actions.getAWinningImage:
      winningImagesList = state.winningImagesList
      if (winningImagesList.length <= 0) {
        winningImagesList = createInitialWinningImagesList()
      }
      winningImage = getRandomItemFromList(winningImagesList)

      return {
        ...state,
        winningImagesList: winningImagesList.filter(e => e !== winningImage),
        winningImage
      }

    case actions.updateIsRoboticVoiceNoticeVisible:
      return {
        ...state,
        isRoboticVoiceNoticeVisible: action.payload
      }

    default:
      return state
  }
}

export default appReducer
