// pages
import React from 'react'
import AboutPage from '../pages/AboutPage'
import AchievementsPage from '../pages/AchievementsPage'
import AdminPanelPage from '../pages/AdminPanelPage'
import ContactErrorPage from '../pages/ContactErrorPage'
import ContactPage from '../pages/ContactPage'
import ContactSuccessPage from '../pages/ContactSuccessPage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage'
import HallOfFamePage from '../pages/HallOfFamePage'
import Home from '../Home'
import LanguageChoice from '../LanguageChoice'
import LanguagePage from '../pages/LanguagePage'
import LogBoubouPage from '../pages/LogBoubouPage'
import MenuPage from '../pages/MenuPage'
import PageNotFoundPage from '../pages/PageNotFoundPage'
import PageNotFoundRedirect from '../pages/PageNotFoundRedirect'
import ParentalConsent from '../pages/ParentalConsent'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import ProfilePage from '../pages/ProfilePage'
import QuestionsPage from '../pages/QuestionsPage'
import SettingsPage from '../pages/SettingsPage'
import SignInPage from '../pages/SignInPage'
import SignUppPage from '../pages/SignUppPage'
import SocialSignUppPage from '../pages/SocialSignUppPage'
import ShopPage from '../pages/ShopPage'
import SupportPage from '../pages/SupportPage'
import TemporarilyUnlockPage from '../pages/TemporarilyUnlockPage'
import TermsOfUsePage from '../pages/TermsOfUsePage'

// WORDS PAGES
import WordsPage from '../pages/WordsPage'
import ColorsPage from '../pages/words/Colors'
import LearnColorsPage from '../pages/words/colors/Learn'
import PlayColorsPage from '../pages/words/colors/Play'
import AnimalsPage from '../pages/words/Animals'
import LearnAnimalsPage from '../pages/words/animals/Learn'
import PlayAnimalsPage from '../pages/words/animals/Play'
import FoodPage from '../pages/words/Food'
import LearnFoodPage from '../pages/words/food/Learn'
import PlayFoodPage from '../pages/words/food/Play'
import LettersAndNumbersPage from '../pages/words/LettersAndNumbers'
import LearnLettersAndNumbersPage from '../pages/words/letters-and-numbers/Learn'
import PlayLettersAndNumbersPage from '../pages/words/letters-and-numbers/Play'
import OtherWords from '../pages/words/OtherWords'

// MATHEMATICS PAGES
import MathematicsPage from '../pages/MathematicsPage'
import AdditionsPage from '../pages/mathematics/Additions'
import BeginnerAdditionsPage from '../pages/mathematics/additions/Beginner'
import EasyAdditionsPage from '../pages/mathematics/additions/Easy'
import MediumAdditionsPage from '../pages/mathematics/additions/Medium'
import HardAdditionsPage from '../pages/mathematics/additions/Hard'
import LearnAdditionsPage from '../pages/mathematics/additions/Learn'
import LearnBeginnerAdditionsPage from '../pages/mathematics/additions/learn/Beginner'
import LearnEasyAdditionsPage from '../pages/mathematics/additions/learn/Easy'
import LearnMediumAdditionsPage from '../pages/mathematics/additions/learn/Medium'
import SubtractionsPage from '../pages/mathematics/Subtractions'
import BeginnerSubtractionsPage from '../pages/mathematics/subtractions/Beginner'
import EasySubtractionsPage from '../pages/mathematics/subtractions/Easy'
import MediumSubtractionsPage from '../pages/mathematics/subtractions/Medium'
import HardSubtractionsPage from '../pages/mathematics/subtractions/Hard'
import LearnSubtractionsPage from '../pages/mathematics/subtractions/Learn'
import LearnBeginnerSubtractionsPage from '../pages/mathematics/subtractions/learn/Beginner'
import LearnEasySubtractionsPage from '../pages/mathematics/subtractions/learn/Easy'
import LearnMediumSubtractionsPage from '../pages/mathematics/subtractions/learn/Medium'
import MultiplicationsPage from '../pages/mathematics/Multiplications'
import BeginnerMultiplicationsPage from '../pages/mathematics/multiplications/Beginner'
import EasyMultiplicationsPage from '../pages/mathematics/multiplications/Easy'
import MediumMultiplicationsPage from '../pages/mathematics/multiplications/Medium'
import HardMultiplicationsPage from '../pages/mathematics/multiplications/Hard'
import LearnMultiplicationsPage from '../pages/mathematics/multiplications/Learn'
import LearnBeginnerMultiplicationsPage from '../pages/mathematics/multiplications/learn/Beginner'
import LearnEasyMultiplicationsPage from '../pages/mathematics/multiplications/learn/Easy'
import LearnMediumMultiplicationsPage from '../pages/mathematics/multiplications/learn/Medium'
import DivisionsPage from '../pages/mathematics/Divisions'
import BeginnerDivisionsPage from '../pages/mathematics/divisions/Beginner'
import BeginnerDivisionsQuotientPage from '../pages/mathematics/divisions/BeginnerQuotient'
import BeginnerDivisionsRemainderPage from '../pages/mathematics/divisions/BeginnerRemainder'
import EasyDivisionsPage from '../pages/mathematics/divisions/Easy'
import EasyDivisionsQuotientPage from '../pages/mathematics/divisions/EasyQuotient'
import EasyDivisionsRemainderPage from '../pages/mathematics/divisions/EasyRemainder'
import MediumDivisionsPage from '../pages/mathematics/divisions/Medium'
import MediumDivisionsQuotientPage from '../pages/mathematics/divisions/MediumQuotient'
import MediumDivisionsRemainderPage from '../pages/mathematics/divisions/MediumRemainder'
import HardDivisionsPage from '../pages/mathematics/divisions/Hard'
import HardDivisionsQuotientPage from '../pages/mathematics/divisions/HardQuotient'
import HardDivisionsRemainderPage from '../pages/mathematics/divisions/HardRemainder'
import LearnDivisionsPage from '../pages/mathematics/divisions/Learn'
import LearnBeginnerDivisionsPage from '../pages/mathematics/divisions/learn/Beginner'
import LearnEasyDivisionsPage from '../pages/mathematics/divisions/learn/Easy'
import LearnMediumDivisionsPage from '../pages/mathematics/divisions/learn/Medium'

// GEOGRAPHY PAGES
import GeographyPage from '../pages/GeographyPage'
import CapitalsPage from '../pages/geography/Capitals'
import LearnCapitalsPage from '../pages/geography/capitals/Learn'
import LearnFamousCapitalsPage from '../pages/geography/capitals/learn/Famous'
import LearnOtherCapitalsPage from '../pages/geography/capitals/learn/Others'
import EasyCapitalsPage from '../pages/geography/capitals/Easy'
import MediumCapitalsPage from '../pages/geography/capitals/Medium'
import HardCapitalsPage from '../pages/geography/capitals/Hard'
import ReverseCapitalsPage from '../pages/geography/capitals/Reverse'
import EasyReverseCapitalsPage from '../pages/geography/capitals/reverse/Easy'
import MediumReverseCapitalsPage from '../pages/geography/capitals/reverse/Medium'
import HardReverseCapitalsPage from '../pages/geography/capitals/reverse/Hard'
import FlagsPage from '../pages/geography/Flags'
import LearnFlagsPage from '../pages/geography/flags/Learn'
import LearnFamousFlagsPage from '../pages/geography/flags/learn/Famous'
import LearnOtherFlagsPage from '../pages/geography/flags/learn/Others'
import EasyFlagsPage from '../pages/geography/flags/Easy'
import MediumFlagsPage from '../pages/geography/flags/Medium'
import HardFlagsPage from '../pages/geography/flags/Hard'
import LocationsPage from '../pages/geography/Locations'
import LearnLocationsPage from '../pages/geography/locations/Learn'
import LearnFamousLocationsPage from '../pages/geography/locations/learn/Famous'
import LearnOtherLocationsPage from '../pages/geography/locations/learn/Others'
import EasyLocationsPage from '../pages/geography/locations/Easy'
import MediumLocationsPage from '../pages/geography/locations/Medium'
import HardLocationsPage from '../pages/geography/locations/Hard'
import CountrySelectPage from '../pages/geography/CountrySelect'

// READING PAGES
import ReadingPage from '../pages/ReadingPage'
import WordsReadingPage from '../pages/reading/WordsReading'
import EasyWordsReadingPage from '../pages/reading/words/Easy'
import MediumWordsReadingPage from '../pages/reading/words/Medium'
import HardWordsReadingPage from '../pages/reading/words/Hard'
import SentencesReadingPage from '../pages/reading/SentencesReading'
import EasySentencesReadingPage from '../pages/reading/sentences/Easy'
import MediumSentencesReadingPage from '../pages/reading/sentences/Medium'
import HardSentencesReadingPage from '../pages/reading/sentences/Hard'
import StoriesReadingPage from '../pages/reading/StoriesReading'

const routeComponentMap = {
  '/': <LanguageChoice />,
  '/about': <AboutPage />,
  '/achievements': <AchievementsPage />,
  '/admin-panel': <AdminPanelPage />,
  '/contact': <ContactPage />,
  '/contact-error': <ContactErrorPage />,
  '/contact-success': <ContactSuccessPage />,
  '/forgot-password': <ForgotPasswordPage />,
  '/hall-of-fame': <HallOfFamePage />,
  '/home': <Home />,
  '/language': <LanguagePage />,
  '/log-boubou': <LogBoubouPage />,
  '/menu': <MenuPage />,
  '/parental-consent': <ParentalConsent />,
  '/privacy-policy': <PrivacyPolicyPage />,
  '/profile': <ProfilePage />,
  '/questions': <QuestionsPage />,
  '/settings': <SettingsPage />,
  '/shop': <ShopPage />,
  '/sign-in': <SignInPage />,
  '/sign-up': <SignUppPage />,
  '/social-sign-up': <SocialSignUppPage />,
  '/support': <SupportPage />,
  '/temporarily-unlock': <TemporarilyUnlockPage />,
  '/terms-of-use': <TermsOfUsePage />,

  // WORDS
  '/words': <WordsPage />,
  '/words/colors': <ColorsPage />,
  '/words/colors/learn': <LearnColorsPage />,
  '/words/colors/play': <PlayColorsPage />,
  '/words/animals': <AnimalsPage />,
  '/words/animals/learn': <LearnAnimalsPage />,
  '/words/animals/play': <PlayAnimalsPage />,
  '/words/food': <FoodPage />,
  '/words/food/learn': <LearnFoodPage />,
  '/words/food/play': <PlayFoodPage />,
  '/words/letters-and-numbers': <LettersAndNumbersPage />,
  '/words/letters-and-numbers/learn': <LearnLettersAndNumbersPage />,
  '/words/letters-and-numbers/play': <PlayLettersAndNumbersPage />,
  '/words/other-words': <OtherWords />,

  // MATHEMATICS
  '/mathematics': <MathematicsPage />,
  '/mathematics/additions': <AdditionsPage />,
  '/mathematics/additions/learn': <LearnAdditionsPage />,
  '/mathematics/additions/learn/beginner': <LearnBeginnerAdditionsPage />,
  '/mathematics/additions/learn/easy': <LearnEasyAdditionsPage />,
  '/mathematics/additions/learn/medium': <LearnMediumAdditionsPage />,
  '/mathematics/additions/beginner': <BeginnerAdditionsPage />,
  '/mathematics/additions/easy': <EasyAdditionsPage />,
  '/mathematics/additions/medium': <MediumAdditionsPage />,
  '/mathematics/additions/hard': <HardAdditionsPage />,
  '/mathematics/subtractions': <SubtractionsPage />,
  '/mathematics/subtractions/learn': <LearnSubtractionsPage />,
  '/mathematics/subtractions/learn/beginner': <LearnBeginnerSubtractionsPage />,
  '/mathematics/subtractions/learn/easy': <LearnEasySubtractionsPage />,
  '/mathematics/subtractions/learn/medium': <LearnMediumSubtractionsPage />,
  '/mathematics/subtractions/beginner': <BeginnerSubtractionsPage />,
  '/mathematics/subtractions/easy': <EasySubtractionsPage />,
  '/mathematics/subtractions/medium': <MediumSubtractionsPage />,
  '/mathematics/subtractions/hard': <HardSubtractionsPage />,
  '/mathematics/multiplications': <MultiplicationsPage />,
  '/mathematics/multiplications/learn': <LearnMultiplicationsPage />,
  '/mathematics/multiplications/learn/beginner': <LearnBeginnerMultiplicationsPage />,
  '/mathematics/multiplications/learn/easy': <LearnEasyMultiplicationsPage />,
  '/mathematics/multiplications/learn/medium': <LearnMediumMultiplicationsPage />,
  '/mathematics/multiplications/beginner': <BeginnerMultiplicationsPage />,
  '/mathematics/multiplications/easy': <EasyMultiplicationsPage />,
  '/mathematics/multiplications/medium': <MediumMultiplicationsPage />,
  '/mathematics/multiplications/hard': <HardMultiplicationsPage />,
  '/mathematics/divisions': <DivisionsPage />,
  '/mathematics/divisions/learn': <LearnDivisionsPage />,
  '/mathematics/divisions/learn/beginner': <LearnBeginnerDivisionsPage />,
  '/mathematics/divisions/learn/easy': <LearnEasyDivisionsPage />,
  '/mathematics/divisions/learn/medium': <LearnMediumDivisionsPage />,
  '/mathematics/divisions/beginner': <BeginnerDivisionsPage />,
  '/mathematics/divisions/beginner/quotient': <BeginnerDivisionsQuotientPage />,
  '/mathematics/divisions/beginner/remainder': <BeginnerDivisionsRemainderPage />,
  '/mathematics/divisions/easy': <EasyDivisionsPage />,
  '/mathematics/divisions/easy/quotient': <EasyDivisionsQuotientPage />,
  '/mathematics/divisions/easy/remainder': <EasyDivisionsRemainderPage />,
  '/mathematics/divisions/medium': <MediumDivisionsPage />,
  '/mathematics/divisions/medium/quotient': <MediumDivisionsQuotientPage />,
  '/mathematics/divisions/medium/remainder': <MediumDivisionsRemainderPage />,
  '/mathematics/divisions/hard': <HardDivisionsPage />,
  '/mathematics/divisions/hard/quotient': <HardDivisionsQuotientPage />,
  '/mathematics/divisions/hard/remainder': <HardDivisionsRemainderPage />,

  // GEOGRAPHY
  '/geography': <GeographyPage />,
  '/geography/capitals': <CapitalsPage />,
  '/geography/capitals/learn': <LearnCapitalsPage />,
  '/geography/capitals/learn/famous-ones': <LearnFamousCapitalsPage />,
  '/geography/capitals/learn/others': <LearnOtherCapitalsPage />,
  '/geography/capitals/easy': <EasyCapitalsPage />,
  '/geography/capitals/medium': <MediumCapitalsPage />,
  '/geography/capitals/hard': <HardCapitalsPage />,
  '/geography/capitals/reverse': <ReverseCapitalsPage />,
  '/geography/capitals/reverse/easy': <EasyReverseCapitalsPage />,
  '/geography/capitals/reverse/medium': <MediumReverseCapitalsPage />,
  '/geography/capitals/reverse/hard': <HardReverseCapitalsPage />,
  '/geography/flags': <FlagsPage />,
  '/geography/flags/learn': <LearnFlagsPage />,
  '/geography/flags/learn/famous-ones': <LearnFamousFlagsPage />,
  '/geography/flags/learn/others': <LearnOtherFlagsPage />,
  '/geography/flags/easy': <EasyFlagsPage />,
  '/geography/flags/medium': <MediumFlagsPage />,
  '/geography/flags/hard': <HardFlagsPage />,
  '/geography/locations': <LocationsPage />,
  '/geography/locations/learn': <LearnLocationsPage />,
  '/geography/locations/learn/famous-ones': <LearnFamousLocationsPage />,
  '/geography/locations/learn/others': <LearnOtherLocationsPage />,
  '/geography/locations/easy': <EasyLocationsPage />,
  '/geography/locations/medium': <MediumLocationsPage />,
  '/geography/locations/hard': <HardLocationsPage />,
  '/geography/country-select': <CountrySelectPage />,

  // READING
  '/reading': <ReadingPage />,
  '/reading/read-words': <WordsReadingPage />,
  '/reading/read-words/easy': <EasyWordsReadingPage />,
  '/reading/read-words/medium': <MediumWordsReadingPage />,
  '/reading/read-words/hard': <HardWordsReadingPage />,
  '/reading/read-sentences': <SentencesReadingPage />,
  '/reading/read-sentences/easy': <EasySentencesReadingPage />,
  '/reading/read-sentences/medium': <MediumSentencesReadingPage />,
  '/reading/read-sentences/hard': <HardSentencesReadingPage />,
  '/reading/read-stories': <StoriesReadingPage />,

  '/page-not-found': <PageNotFoundPage />,
  '*': <PageNotFoundRedirect />
}

const routeList = Object.keys(routeComponentMap)

const createWordPathMapFor = name => {
  const pathName = name.replace(/_/g, '-')
  return {
    [`${name}_page`]: `/words/${pathName}`,
    [`learn_${name}`]: `/words/${pathName}/learn`,
    [`play_${name}_page`]: `/words/${pathName}/play`
  }
}

const createLearnMathematicsPathMapFor = name => {
  return {
    [`learn_${name}_page`]: `/mathematics/${name}/learn`,
    [`learn_beginner_${name}`]: `/mathematics/${name}/learn/beginner`,
    [`learn_easy_${name}`]: `/mathematics/${name}/learn/easy`,
    [`learn_medium_${name}`]: `/mathematics/${name}/learn/medium`
  }
}

const createMathematicsPathMapFor = name => {
  return {
    [`${name}_page`]: `/mathematics/${name}`,
    ...createLearnMathematicsPathMapFor(name),
    [`beginner_${name}`]: `/mathematics/${name}/beginner`,
    [`easy_${name}`]: `/mathematics/${name}/easy`,
    [`medium_${name}`]: `/mathematics/${name}/medium`,
    [`hard_${name}`]: `/mathematics/${name}/hard`
  }
}

const createDivisionPathMapForLevel = level => {
  return {
    [`${level}_level_divisions_page`]: `/mathematics/divisions/${level}`,
    [`${level}_divisions_quotient`]: `/mathematics/divisions/${level}/quotient`,
    [`${level}_divisions_remainder`]: `/mathematics/divisions/${level}/remainder`
  }
}

const createGeographyPathMapFor = name => {
  return {
    [`${name}_page`]: `/geography/${name}`,
    [`learn_${name}_page`]: `/geography/${name}/learn`,
    [`learn_famous_${name}`]: `/geography/${name}/learn/famous-ones`,
    [`learn_other_${name}`]: `/geography/${name}/learn/others`,
    [`easy_${name}`]: `/geography/${name}/easy`,
    [`medium_${name}`]: `/geography/${name}/medium`,
    [`hard_${name}`]: `/geography/${name}/hard`
  }
}

// mapping needed for <Navigate /> since React-router 6
const pageNamePathMap = {
  '/': '/',
  home_page: '/home',
  menu_page: '/menu',
  about_page: '/about',
  achievements_page: '/achievements',
  settings_page: '/settings',
  shop_page: '/shop',
  hall_of_fame_page: '/hall-of-fame',
  support_page: '/support',
  questions_page: '/questions',
  contact_page: '/contact',
  language_page: '/language',
  profile_page: '/profile',

  // WORDS
  words_page: '/words',
  ...createWordPathMapFor('colors'),
  ...createWordPathMapFor('animals'),
  ...createWordPathMapFor('food'),
  ...createWordPathMapFor('letters_and_numbers'),
  other_words_words: '/words/other-words',

  // MATHEMATICS
  mathematics_page: '/mathematics',
  ...createMathematicsPathMapFor('additions'),
  ...createMathematicsPathMapFor('subtractions'),
  ...createMathematicsPathMapFor('multiplications'),
  divisions_page: '/mathematics/divisions',
  ...createLearnMathematicsPathMapFor('divisions'),
  ...createDivisionPathMapForLevel('beginner'),
  ...createDivisionPathMapForLevel('easy'),
  ...createDivisionPathMapForLevel('medium'),
  ...createDivisionPathMapForLevel('hard'),

  // GEOGRAPHY
  geography_page: '/geography',
  ...createGeographyPathMapFor('capitals'),
  reverse_capitals_page: '/geography/capitals/reverse',
  easy_reverse_capitals: '/geography/capitals/reverse/easy',
  medium_reverse_capitals: '/geography/capitals/reverse/medium',
  hard_reverse_capitals: '/geography/capitals/reverse/hard',
  ...createGeographyPathMapFor('flags'),
  ...createGeographyPathMapFor('locations'),
  country_select_page: '/geography/country-select',

  // READING
  reading_page: '/reading',
  read_words_page: '/reading/read-words',
  read_sentences_page: '/reading/read-sentences',
  read_stories_page: '/reading/read-stories'
}

const wasPathChanged = (path, pageName) => {
  const wasParentsInfoButtonHashLinkClick =
    path === '/questions#microphone_not_picking_up' && pageName === 'questions_page'

  return wasParentsInfoButtonHashLinkClick ? false : path !== pageNamePathMap[pageName]
}

export { routeComponentMap, routeList, wasPathChanged }
