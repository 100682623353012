import i18next from 'i18next'
import fr from 'date-fns/locale/fr'
import { updateLanguageAction } from '../../redux/actions/actions'
import { setAnnyangLanguage } from '../speechToTextUtils/annyangSpeechToText'
import { createInvertedKeysValuesObject, makeLowerCaseArray } from '../dataUtil'
import {
  addEnAAn,
  enFoodQuestionSentence,
  enFoodAnswerSentence,
  enLettersAndNumbersQuestionSentence,
  enLettersAndNumbersAnswerSentence
} from './englishUtil'
import {
  of,
  ofLocation,
  frUnUneDuOrDes,
  frFoodQuestionSentence,
  frLettersAndNumbersQuestionSentence,
  frLettersAndNumbersAnswerSentence,
  addEstOrSont
} from './frenchUtil'
import { isLetter } from '../methodsUtil'
import { removeCamelCase } from '../topicsUtils/geographyUtil'

const lngMap = {
  en: { English: 'en-GB', French: 'fr-FR' },
  fr: { Anglais: 'en-GB', Français: 'fr-FR' }
}

const supportedLngCodes = Object.keys(lngMap) // ['en', 'fr']

const languageToLngCodeMap = {
  'en-US': 'en',
  en: 'en',
  'en-GB': 'en', // default value needs to be last as used in inverted object
  fr: 'fr',
  'fr-FR': 'fr'
}

const ownLanguageIntro = {
  supported: {
    en: 'For English, say "English"',
    fr: 'Pour Français, dire "French"'
  },
  notSupported: {
    en: 'For English, click the English flag',
    fr: 'Pour Français, cliques sur le drapeau Français'
  }
}

const lngGendersMap = {
  en: { man: 'man', woman: 'woman' },
  fr: { homme: 'man', femme: 'woman' }
}

const createSupportedlngKeysFor = lngMap => {
  const newObject = {}
  supportedLngCodes.forEach(lngCode => {
    newObject[lngCode] = Object.keys(lngMap[lngCode])
  })
  return newObject
}

const supportedLanguagesNames = createSupportedlngKeysFor(lngMap)

const supportedGenders = createSupportedlngKeysFor(lngGendersMap)

const allSupportedLanguages = Object.keys(languageToLngCodeMap)

const soundLevelsMap = {
  en: { 'sound on': 'on', 'answers only': 'partial', 'sound off': 'off' },
  // also adding the AcceptedOrtograph for these settings directly here
  fr: {
    'avec son': 'on',
    'réponse seulement': 'partial',
    'sans son': 'off',
    sanson: 'off',
    samson: 'off'
  }
}

const supportedSoundLevels = createSupportedlngKeysFor(soundLevelsMap)

const getLngCode = language =>
  allSupportedLanguages.includes(language) ? languageToLngCodeMap[language] : 'en'

const lngCodeToLanguageMap = createInvertedKeysValuesObject(languageToLngCodeMap)

const findSpokenFromList = (lngCode, transcript, allowedList) =>
  makeLowerCaseArray(allowedList[lngCode]).find(allowedWord =>
    makeLowerCaseArray(transcript).find(ts => ts === allowedWord)
  )

const genderSupportedLanguages = ['en-GB']

// Silly exception. AllowedWordsList has been filtered to exclude selected gender
// but in English, when "Man" is said, it's found in "Woman" causing a single exception that crashes the app
// an exception methods has been made just for this case
const isWordManInWomanException = (word, allowedWordsList) =>
  word === 'man' && allowedWordsList.join().toLowerCase().includes('woman')

// London or Paris / Londres ou Paris
const orObject = {
  en: 'or',
  fr: 'ou'
}
const or = lngCode => orObject[lngCode]

const andObject = {
  en: 'and',
  fr: 'et'
}
const and = lngCode => andObject[lngCode]

const createStringWithAndForLastItemInList = (arr, lngCode) => {
  if (arr.length > 1) {
    const arrString = arr.join(', ')
    const lastComaIndex = arrString.lastIndexOf(',')
    const lastItem = arrString.slice(lastComaIndex).replace(', ', '')
    return `${arrString.substring(0, lastComaIndex)} ${and(lngCode)} ${lastItem}`.toLowerCase()
  }
  return arr.toString()
}

const the = country => {
  const renamedCountry = removeCamelCase(country)?.replace(
    'Congo DR',
    'Democratic Republic of the Congo'
  )
  const theCountriesList = [
    'Democratic Republic of the Congo',
    'Dominican Republic',
    'Marshall Islands',
    'Netherlands',
    'Seychelles',
    'Solomon Islands',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Vatican'
  ]
  return theCountriesList.includes(renamedCountry) ? `the ${renamedCountry}` : renamedCountry
}

const ofCountryModifier = {
  en: the,
  fr: of
}

const ofCountryLocationModifier = {
  en: the,
  fr: ofLocation
}

const its = lng => (lng === 'en' ? `it's` : `c'est`)

const oneAndTwoMap = {
  en: {
    0: [`it's zero`, `number zero`],
    1: [`it's one`, `number one`],
    2: [`it's two`, `number two`, `it's too`],
    E: [],
    L: [],
    U: [`it's you`],
    Y: [`it's why`]
  },
  fr: {
    0: [`c'est zéro`, `chiffre zéro`],
    1: [`c'est un`, `chiffre un`],
    2: [`c'est deux`, `chiffre deux`],
    E: [`c'est eux`],
    L: [`c'est elle`],
    U: [],
    Y: []
  }
}

// Note: the ':' in the answers is not shown in the answers but separate the view in <StyledAnswer />
const translatedQuestionsAndAnswers = {
  words: {
    colors: {
      questionText: {
        en: 'What is this color?',
        fr: 'Quel est cette couleur?'
      },
      questionAnswer: {
        en: color => `It's :${color}`,
        fr: color => `C'est :${color}`
      }
    },
    lettersAndNumbers: {
      questionText: {
        en: letterAndNumber => enLettersAndNumbersQuestionSentence(letterAndNumber),
        fr: letterAndNumber => frLettersAndNumbersQuestionSentence(letterAndNumber)
      },
      questionAnswer: {
        en: letterAndNumber => enLettersAndNumbersAnswerSentence(letterAndNumber),
        fr: letterAndNumber => frLettersAndNumbersAnswerSentence(letterAndNumber)
      }
    },
    animals: {
      questionText: {
        en: 'Which animal is this?',
        fr: 'Quel est cet animal?'
      },
      questionAnswer: {
        en: animal => (animal ? `It's ${animal && addEnAAn(animal)} :${animal}` : ''),
        fr: animal => (animal ? `C'est ${frUnUneDuOrDes(animal)} :${animal}` : '')
      }
    },
    food: {
      questionText: {
        en: food => enFoodQuestionSentence(food),
        fr: food => frFoodQuestionSentence(food)
      },
      questionAnswer: {
        en: food => (food ? `${enFoodAnswerSentence(food)} :${food}` : ''),
        fr: food =>
          food
            ? `C'est ${frUnUneDuOrDes(food)} :${food?.replace('glasse', 'glace')}`.replace(
                `l' :`,
                `l':`
              )
            : ''
      }
    }
  },
  geography: {
    capitals: {
      questionText: {
        en: (country, multipleChoices) => {
          const theCountry = the(removeCamelCase(country))
          return multipleChoices
            ? `What is the capital of ${theCountry}? Is it ${multipleChoices}`
            : `What is the capital of ${theCountry}?`
        },
        fr: (country, multipleChoices) => {
          const ofCountry = of(country)
          return multipleChoices
            ? `Quelle est la capitale ${ofCountry}? Est-ce que c'est ${multipleChoices}`
            : `Quelle est la capitale ${ofCountry}?`
        }
      },
      questionAnswer: {
        en: (capital, answerQuestionMap) =>
          `The capital of ${the(removeCamelCase(answerQuestionMap.en[capital]))} is :${capital}`,
        fr: (capital, answerQuestionMap) =>
          `La capitale ${of(answerQuestionMap.fr[capital])} est :${capital}`
      },
      reverseQuestionText: {
        en: (capital, multipleChoices) =>
          multipleChoices
            ? `${capital} is the capital of which country? Is it ${multipleChoices}`
            : `${capital} is the capital of which country?`,
        fr: (capital, multipleChoices) => {
          return multipleChoices
            ? `${capital} est la capitale de quel pays? Est-ce que c'est ${multipleChoices}`
            : `${capital} est la capitale de quel pays?`
        }
      },
      reverseQuestionAnswer: {
        en: (country, invertedAnswerQuestionMap) =>
          `${invertedAnswerQuestionMap.en[country]} is the capital of :${the(
            removeCamelCase(country)
          )}`,
        fr: (country, invertedAnswerQuestionMap) =>
          `${invertedAnswerQuestionMap.fr[country]} est la capitale :${of(country)}`
      }
    },
    flags: {
      questionText: {
        en: multipleChoices =>
          multipleChoices
            ? `Which country has this flag? Is it ${multipleChoices}`
            : 'Which country has this flag?',
        fr: multipleChoices =>
          multipleChoices
            ? `Quel pays a ce drapeau? Est-ce que c'est celui ${multipleChoices}`
            : 'Quel pays a ce drapeau?'
      },
      questionAnswer: {
        en: country => `This flag is the one of :${the(country)}`,
        fr: country => `Ce drapeau est celui :${of(country)}`
      }
    },
    locations: {
      questionText: {
        en: multipleChoices =>
          multipleChoices
            ? `Which country is located here? Is it ${multipleChoices}`
            : 'Which country is located here?',
        fr: multipleChoices =>
          multipleChoices
            ? `Quel pays se trouve ici? Est-ce que c'est ${multipleChoices}`
            : 'Quel pays se trouve ici?'
      },
      questionAnswer: {
        en: country => `this country is :${the(country)}`,
        fr: country => `Ce pays est :${ofLocation(country)}`
      }
    }
  },
  reading: {
    words: {
      questionText: {
        en: 'What is this word?',
        fr: 'Quel est ce mot?'
      },
      questionAnswer: {
        en: word => `It's :${word}`,
        fr: word => `C'est :${word}`
      }
    },
    sentences: {
      questionText: {
        en: 'What is this sentence?',
        fr: 'Quelle est cette phrase?'
      },
      questionAnswer: {
        en: word => `It's :${word}`,
        fr: word => `C'est :${word}`
      }
    }
  }
}

const updateLanguageWith = (language, dispatch) => {
  let supportedLanguage = allSupportedLanguages.includes(language) ? language : 'en-GB'
  // when tested on French google, the detected language came back as fr; and not fr-FR so we update it.
  if (supportedLanguage.length == 2) {
    supportedLanguage = lngCodeToLanguageMap[supportedLanguage]
  }
  i18next.changeLanguage(supportedLanguage)
  setAnnyangLanguage(supportedLanguage)
  dispatch(updateLanguageAction(supportedLanguage))
}

const translateFrom = pageName => (key, subKey) =>
  subKey ? i18next.t(`${pageName}.${subKey}.${key}`) : i18next.t(`${pageName}.${key}`)

const common = translateFrom('common')

const translateLearnMathPages = translateFrom('learn_math_pages')

const commonTranslateArray = arr => arr.map(key => common(key).toLowerCase())

const instructionTranslate = translateFrom('instructions')

// Used & works for Topics only / language specific accepted words to navigate
// in French for example, 'les mots' can be understood as 'les maux'
const acceptedSpellingCommands = {
  fr: {
    paramètres: ['paramètre'],
    'les mots': ['les maux'],
    couleurs: ['couleur'],
    'autres mots': ['autre mot', 'autrement', 'autres mot'],
    'chiffres et lettres': ['chiffre et lettre'],
    additions: ['addition'],
    soustractions: ['soustraction'],
    multiplications: ['multiplication'],
    divisions: ['division'],
    capitales: ['capitale'],
    drapeaux: ['drapeau'],
    localisations: ['localisation'],
    inversé: ['inversée', 'inverser', 'inversez'],
    questions: ['question'] // FAQ
  }
}

const addAcceptedSpellingCommands = (language, navigationObject) => {
  const languageCommands = acceptedSpellingCommands[language]
  if (languageCommands) {
    for (let command in languageCommands) {
      if (navigationObject[command]) {
        const acceptedWords = languageCommands[command]
        acceptedWords.forEach(word => {
          navigationObject[word] = navigationObject[command]
        })
      }
    }
  }
  return navigationObject
}

const acceptedSpellings = {
  en: {
    "i don't know": ["i don't know", "don't know", 'dont know'],
    'where am i': ['where am i', "where i'm at", 'where I am at']
  },
  fr: {
    'je ne sais pas': ['je ne sais pas', 'je sais pas', 'Jay sais pas'],
    'où suis-je': [
      'où suis-je',
      'je suis où',
      'où que je suis',
      'chui où',
      'je suis où là',
      'où que je suis là'
    ]
  }
}

// if a result such as `1` is expected, Annyang will understand "one" (en) or "un" (fr); and count the result
// as false. Becomes sometimes, Annyang only record on 2 syllabs I also handled double the word to pick up
// when a given answer is correct, from 10+, Annyang pick up as number susch as "69"
// LettersAndNumbersInfo
const numbersToWordsMap = {
  en: {
    1: ['one', 'one one', '1 1', "it's one", "it's 1"],
    2: ['two', 'two two', '2 2', "it's two", "it's 2"],
    3: ['three', 'three three', '3 3', "it's three", "it's 3"],
    4: ['four', 'four four', '4 4', "it's four", "it's 4"],
    5: ['five', 'five five', '5 5', "it's five", "it's 5"],
    6: ['six', 'six six', '6 6', "it's six", "it's 6"],
    7: ['seven', 'seven seven', '7 7', "it's seven", "it's 7"],
    8: ['eight', 'eight eight', '8 8', "it's eight", "it's 9"],
    9: ['nine', 'nine nine', '9 9', "it's nine", "it's 9"],
    10: ['ten', 'ten ten', '10 10', "it's ten", "it's 10"]
  },
  fr: {
    1: ['un', 'un un', '1 1', "c'est un", "c'est 1"],
    2: ['deux', 'deux deux', '2 2', "c'est duex", "c'est 2"],
    3: ['trois', 'trois trois', '3 3', "c'est trois", "c'est 3"],
    4: ['quatre', 'quatre quatre', '4 4', "c'est quatre", "c'est 4"],
    5: ['cinq', 'cinq cinq', '5 5', "c'est cinq", "c'est 5"],
    6: ['six', 'six six', '6 6', "c'est six", "c'est 6"],
    7: ['sept', 'sept sept', '7 7', "c'est sept", "c'est 7"],
    8: ['huit', 'huit huit', '8 8', "c'est huit", "c'est 8"],
    9: ['neuf', 'neuf neuf', '9 9', "c'est neuf", "c'est 9"],
    10: ['dix', 'dix dix', '10 10', "c'est dix", "c'est 10"],
    20: ['vingt', 'vingt vingt', '20 20', "c'est vingt", "c'est 20"],
    30: ['trente', 'trente trente', '30 30', "c'est trente", "c'est 30"],
    100: ['cent', 'sans', 'cent cent', 'sans sans', '100 100', "c'est cent", "c'est 100"],
    1000: ['mille', 'mille mille', '1000 1000', "c'est mille", "c'est 1000"]
  }
}

const addIsOrAre = theName => (theName.indexOf('the ') === 0 ? `${theName} are` : `${theName} is`)

const translatedLearning = {
  learn_colors: {
    en: color => [color],
    fr: color => [color]
  },
  learn_animals: {
    en: animal => [animal],
    fr: animal => [animal]
  },
  learn_food: {
    en: food => [food],
    fr: food => [food]
  },
  learn_letters_and_numbers: {
    en: actual => [`${isLetter(actual) ? 'letter' : 'number'} ${actual}`],
    fr: actual => [`${isLetter(actual) ? 'lettre' : 'chiffre'} ${actual}`]
  },
  learn_capitals: {
    en: (actual, extra) => [
      `The capital of ${ofCountryModifier.en(extra)} is ${actual}`,
      `${actual} is the capital of ${ofCountryModifier.en(extra)}`,
      ` ${ofCountryModifier.en(extra)}'s capital is called ${actual}`
    ],
    fr: (actual, extra) => [
      `La capitale ${ofCountryModifier.fr(extra)} est ${actual}`,
      `${actual} est la capitale ${ofCountryModifier.fr(extra)}`,
      `La capitale ${ofCountryModifier.fr(extra)} s'appelle ${actual}`
    ]
  },
  learn_flags: {
    en: actual => [
      `This flag is the one of ${ofCountryModifier.en(actual)}`,
      `${ofCountryModifier.en(actual)} is represented by this flag`,
      `This flag is the flag of ${ofCountryModifier.en(actual)}`,
      `This is the one of ${ofCountryModifier.en(actual)}`,
      `This one belongs to ${ofCountryModifier.en(actual)}`,
      `These are the colous of ${ofCountryModifier.en(actual)}`,
      actual
    ],
    fr: actual => [
      `Ce drapeau est celui ${ofCountryModifier.fr(actual)}`,
      `${addEstOrSont(ofLocation(actual))} représenté par ce drapeau`,
      `Ça, c'est le drapeau ${ofCountryModifier.fr(actual)}`,
      `Ça, c'est celui ${ofCountryModifier.fr(actual)}`,
      `Voilà les couleurs ${ofCountryModifier.fr(actual)}`,
      actual
    ]
  },
  learn_locations: {
    en: actual => [
      `${addIsOrAre(ofCountryModifier.en(actual))} located here`,
      `The country located here is ${ofCountryModifier.en(actual)}`,
      `${ofCountryModifier.en(actual)} can be found at this location`,
      `This is ${ofCountryModifier.en(actual)}`,
      `That's ${ofCountryModifier.en(actual)}`,
      `You will find ${ofCountryModifier.en(actual)} here`,
      actual
    ],
    fr: actual => [
      `${ofLocation(actual)} se trouve là`,
      `${ofLocation(actual)} se trouve ici`,
      `Le pays qui se trouve là, c'est ${ofLocation(actual)}`,
      `Le pays qui se trouve ici, c'est ${ofLocation(actual)}`,
      `Cette localisation est celle ${ofCountryModifier.fr(actual)}`,
      `Ça, c'est ${ofLocation(actual)}`,
      `Là, c'est ${ofLocation(actual)}`,
      actual
    ]
  }
}

const makeWhereIAmText = {
  en: ({ title, isGamePage = false, isLearnPage = false, actsAsLearnMenu = false }) => {
    if (title) {
      const isLearn = isLearnPage || actsAsLearnMenu
      const titleSplit = title.split(':')
      const mainTitle = titleSplit[0]
      const subTitle = titleSplit[1]
      return `you are on the ${isLearn ? '' : mainTitle} ${isGamePage ? 'game' : ''} page ${isLearn ? `to ${mainTitle}` : ''}. ${subTitle ? `find the ${subTitle}` : ''}`
    }
  },
  fr: ({ title, isGamePage = false, isLearnPage = false, actsAsLearnMenu = false }) => {
    let mainTitle
    let subTitle
    const isLearn = isLearnPage || actsAsLearnMenu
    let preposition = ''
    if (title) {
      const titleSplit = title.split(':')
      mainTitle = titleSplit[0]
      subTitle = titleSplit[1]
      preposition = frUnUneDuOrDes(title.toLowerCase())
      preposition =
        [`À propos`].some(t => title.includes(t)) || isLearn
          ? ''
          : [`Soutenir`].some(t => title.includes(t))
            ? 'pour'
            : preposition === `d'`
              ? preposition
              : `${preposition} `
    }
    return `Tu es sur la page ${isGamePage ? 'de jeu' : isLearn ? 'pour' : ''} ${`${preposition}${mainTitle}`}. ${subTitle ? `trouve le ${subTitle}` : ''}`
  }
}

// default to empty string, which reverts to English
const getDatePickerLocale = lngCode => (lngCode === 'fr' ? fr : '')

export {
  createInvertedKeysValuesObject,
  lngMap,
  supportedLngCodes,
  languageToLngCodeMap,
  supportedLanguagesNames,
  lngGendersMap,
  getLngCode,
  lngCodeToLanguageMap,
  findSpokenFromList,
  genderSupportedLanguages,
  isWordManInWomanException,
  soundLevelsMap,
  supportedSoundLevels,
  ownLanguageIntro,
  its,
  oneAndTwoMap,
  translatedQuestionsAndAnswers,
  or,
  createStringWithAndForLastItemInList,
  updateLanguageWith,
  translateFrom,
  common,
  translateLearnMathPages,
  ofCountryModifier,
  ofCountryLocationModifier,
  addAcceptedSpellingCommands,
  acceptedSpellings,
  numbersToWordsMap,
  commonTranslateArray,
  instructionTranslate,
  supportedGenders,
  translatedLearning,
  makeWhereIAmText,
  getDatePickerLocale
}
