import React from 'react'
import { changePathAction } from '../redux/actions/actions'
import { common } from '../lib/languagesUtils/languageUtil'

const NavigateLink = ({ dispatch, navigateUrl, navigateText, className }) => (
  <span
    className={`navigate-link ${className}`}
    onClick={() => dispatch(changePathAction(navigateUrl))}>
    {navigateText || common('here')}
  </span>
)

export default NavigateLink
