import React from 'react'
import { connect } from 'react-redux'
import LettersAndNumbersInfo from '../../../components/LettersAndNumbersInfo'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, wordsMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps } from '../letters-and-numbers/util'
import { isLetter } from '../../../lib/methodsUtil'

const PlayLettersAndNumbersPage = props => {
  const { lngCode = 'en', actual: letterOrNumber, isPlaying } = props

  return (
    <>
      <LettersAndNumbersInfo isPlaying={isPlaying} />
      <QuestionsAnswersGame
        {...props}
        {...commonProps}
        {...gameTypeProps({
          lngCode,
          questionType: 'play_letters_and_numbers',
          letterOrNumber
        })}
        actual={isLetter(letterOrNumber) ? `letter ${letterOrNumber}` : `number ${letterOrNumber}`}
      />
    </>
  )
}

export default connect(wordsMapStateToProps, passDispatchToProps)(PlayLettersAndNumbersPage)
