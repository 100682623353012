import { signInWithPopup } from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import {
  auth,
  db,
  googleProvider,
  facebookProvider,
  appleProvider,
  twitterProvider,
  microsoftProvider
} from '../database/firebase.config'
import { profanityWords } from './dataUtil'

const userDataDefaultProperties = {
  achievementDesignSelect: null,
  achievements: [],
  city: '',
  country: '',
  creationDate: Date.now(),
  dob: '', // to wish a birthday
  firstName: '',
  hasUsedFreeTrial: false,
  hofList: [],
  isEmailVerified: false,
  lastName: '',
  nationality: '',
  notes: '',
  plan: null,
  premium: false,
  isPublic: false,
  settings: {
    language: 'en-GB',
    lngCode: 'en',
    gender: 'man',
    soundLevel: 'on'
  },
  unlockedPremiumTopics: []
}

const socialSignUp = async (provider, callback, errorCallback, emailAlreadyInUseCallback) => {
  try {
    // signInWithRedirect is an option but it didn't work. It actually works on Incognito!
    // Cross-Origin-Opener-Policy policy would block the window.close call.
    // Need to try live in prod with me- domain being registered, else, remove social sign up
    const createUser = await signInWithPopup(auth, provider)
    const user = createUser.user
    const user_email = user.email

    const emailDocRef = doc(db, 'emails', user_email)
    const emailDoc = await getDoc(emailDocRef)

    // If the email alredy exists, we block the registration
    if (emailDoc.exists()) {
      emailAlreadyInUseCallback()
      console.log('Sign in blocked: email already in use.')
      throw new Error('email_already_registered')
    }

    // Proceed with registration if email does not exist
    const user_id = user.uid
    const userDocRef = doc(db, 'users', user_id)
    const userDoc = await getDoc(userDocRef)

    let userData
    if (!userDoc.exists()) {
      userData = {
        userId: user_id,
        email: user_email,
        ...userDataDefaultProperties
      }

      // adds in the emails collection so no other user can use that email
      await setDoc(emailDocRef, { email: user_email })

      // Remove the userId before we put in the state
      // delete userData.userId
      callback(userDocRef, userData)
    }
  } catch (error) {
    errorCallback(error)
  }
}

// Google Sign Up
const googleSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(googleProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Facebook Sign Up
const facebookSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(facebookProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Apple Sign Up
const appleSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(appleProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Twitter (X) Sign Up
const twitterSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(twitterProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Microsoft Sign Up
const microsoftSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(microsoftProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

const filterProfanity = inputString => {
  const substitutions = {
    e: '[e3]',
    i: '[i1l]',
    a: '[a4]',
    o: '[o0]',
    l: '[l1]'
  }

  profanityWords.forEach(word => {
    let regexPattern = word
      .split('')
      .map(char => substitutions[char] || char)
      .join('')
    const regex = new RegExp(regexPattern, 'gi')
    inputString = inputString.replace(regex, '*'.repeat(word.length))
  })

  return inputString
}

export {
  userDataDefaultProperties,
  googleSignUp,
  facebookSignUp,
  appleSignUp,
  twitterSignUp,
  microsoftSignUp,
  filterProfanity
}
