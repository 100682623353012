// import { wasPathChanged } from '../router/routerUtil'

import React from 'react'
import { translateFrom } from '../lib/languagesUtils/languageUtil'

const Input = ({
  name,
  value,
  style,
  onChange,
  onBlur,
  onKeyUp,
  disabled,
  type = 'text',
  className = 'form-md-input-field'
}) => (
  <input
    name={name}
    type={type}
    value={value}
    style={style}
    onChange={onChange}
    onBlur={onBlur}
    onKeyUp={onKeyUp}
    className={className}
    disabled={disabled}
  />
)

const translateError = translateFrom('errors')

const InputError = ({ error, className }) =>
  error.length > 0 && <div className={`form-field-error ${className}`}>{translateError(error)}</div>

export { Input, InputError }
