import { createInvertedKeysValuesObject } from './dataUtil'
// import { baseUrl } from './_processUtil'

// SSS
const cipherMapping = {
  a: 'Z',
  b: '3',
  c: 'T',
  d: '8',
  e: 'w',
  f: 'x',
  g: 'q',
  h: 'D',
  i: 'M',
  j: '9',
  k: 'c',
  l: 'R',
  m: 'V',
  n: '2',
  o: 'L',
  p: 'g',
  q: 'J',
  r: 'H',
  s: 'A',
  t: 'K',
  u: 'O',
  v: 's',
  w: '7',
  x: 'e',
  y: 'B',
  z: 'U',
  A: 'm',
  B: 'a',
  C: 'v',
  D: 'X',
  E: 'k',
  F: 'l',
  G: 't',
  H: 'o',
  I: 'y',
  J: 'W',
  K: 'F',
  L: 'b',
  M: '0',
  N: 'i',
  O: 'f',
  P: '6',
  Q: 'r',
  R: 'G',
  S: 'u',
  T: 'p',
  U: 'Q',
  V: 'z',
  W: 'N',
  X: '1',
  Y: '4',
  Z: 'C',
  0: 'E',
  1: 'I',
  2: 'P',
  3: '5',
  4: 'Y',
  5: 'n',
  6: 'j',
  7: 'h',
  8: 'S',
  9: 'd'
}

const encodeMyParam = (inputString, lettersMap) =>
  inputString
    .split('')
    .map(char => (Object.keys(lettersMap).includes(char) ? lettersMap[char] : char))
    .join('')

const encodeParam = inputString => encodeMyParam(inputString, cipherMapping)

const invertedCipherMapping = createInvertedKeysValuesObject(cipherMapping)
const decodeParam = inputString => encodeMyParam(inputString, invertedCipherMapping)

// SSS - Smaller image
// TODO - replace Gamerzzz with Mini Einstein
const createEmailHTML = (lngCode, email, userId) => `
      <html>
        <body>
          <p>Hello,<br>This message is sent on behalf of your child (${email}).</p>
          <p style="display: inline-block; padding: 10px 15px; background-color: #4CAF50">
            Your child attempted to sign up to Gamerzzz and named you as his/her/their Parent/Guardian. Since under 16, his registration is pending and requires your consent.
          </p>
          <p>
            Please click <strong><a href="http://localhost:8888/parental-consent?${encodeParam(userId)}" 
              style="display: inline-block; padding: 10px 15px; background-color: #4CAF50; color: white; text-decoration: none; border-radius: 5px;">
              here
            </a></strong> to review.
          </p>
          <h4>Gamerzzz.com</h4>
          <img src="https://mini-einstein.com/images/miniEinsteinEmailSignature80" alt="Gamerzzz Logo">
          </body>
      </html>
    `

export { encodeParam, decodeParam, createEmailHTML }
