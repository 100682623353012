import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import LettersAndNumbersInfo from '../../../components/LettersAndNumbersInfo'
import { passDispatchToProps, mathematicsMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps, addNumbers } from '../util'

const EasyAdditionsPage = props => {
  const { lngCode = 'en', actual: numbersArray, resultsList, randomList, isPlaying } = props

  return (
    <>
      <LettersAndNumbersInfo isPlaying={isPlaying} isMath={true} />
      <QuestionsAnswersGame
        {...props}
        {...commonProps}
        {...gameTypeProps({
          lngCode,
          questionType: 'additions',
          questionLevel: 'easy',
          numbersArray,
          result: addNumbers(numbersArray),
          resultsList,
          randomList
        })}
      />
    </>
  )
}

export default connect(mathematicsMapStateToProps, passDispatchToProps)(EasyAdditionsPage)
