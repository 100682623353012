// import { wasPathChanged } from '../router/routerUtil'

import React from 'react'

const makeOptions = (optionsList, optionsEnList) => {
  const saveEnValue = optionsEnList && optionsEnList.length > 0
  return optionsList.map((option, i) => (
    <option value={saveEnValue ? optionsEnList[i] : option} key={i}>
      {option}
    </option>
  ))
}

const Select = ({
  name,
  value,
  optionPlaceholderText,
  optionsList,
  optionsEnList,
  onChange = () => {},
  style,
  className = 'form-md-input-field',
  disabled
}) => (
  <select
    className={className}
    name={name}
    onChange={onChange}
    style={style}
    value={value}
    disabled={disabled}>
    {optionPlaceholderText && (
      <option value="" disabled>
        {optionPlaceholderText}
      </option>
    )}
    {makeOptions(optionsList, optionsEnList)}
  </select>
)

export default Select
