import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
// import '../styles/scss/App.scss'
import {
  startRecording,
  stopRecording,
  isUsingAnnyang
} from '../lib/speechToTextUtils/speechToText'
import {
  translateFrom,
  common,
  commonTranslateArray,
  instructionTranslate
} from '../lib/languagesUtils/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import { baseUrl, isReadOutLoudDisabled } from '../lib/_processUtil'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import {
  isGeographyPage,
  getRandomItemFromList,
  isRedirectButtonClick,
  isHelpRequest,
  isWhereAmIRequest,
  isStartRequest,
  isStopRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isTranscriptAMatch,
  capitalizeWord,
  getPageTitleColor,
  getTopic,
  isPaidUser
} from '../lib/methodsUtil'
import PageHeader from './PageHeader'
import SpeechButton from './SpeechButton'
import ImageWithDimensions from './ImageWithDimensions'
import NavigationButtons from './NavigationButtons'
import FreeVersionBanner from './FreeVersionBanner'
import ReadOutLoudRoboticVoiceNotice from './ReadOutLoudRoboticVoiceNotice'
import LearnDisplay from './LearnDisplay'
import { learnMapStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import { wasPathChanged } from '../router/routerUtil'
import { translatedLearning, makeWhereIAmText } from '../lib/languagesUtils/languageUtil'
import {
  saveTranscriptAction,
  prepareLearningAction,
  startLearningAction,
  updateLearningItemAction,
  resetLearningAction
} from '../redux/actions/actions'

// TODO - Videos instead of the complicated things I did with animations. AI?

const intervalTime = 5000

// This is a hack because for all my cases clearInterval(this.myIntervalRef.current) doesn't work
const clearAllIntervals = () => {
  for (let i = 0; i < 1000000; i++) {
    clearInterval(i)
  }
}

class LearnPage extends Component {
  constructor(props) {
    super(props)
    const { isChrome, isUsingMicrophone, soundLevel } = props
    this.startLearning = this.startLearning.bind(this)
    this.startLearningClick = this.startLearningClick.bind(this)
    this.stopLearningClick = this.stopLearningClick.bind(this)
    this.myIntervalRef = createRef()
    this.state = {
      isStopClick: false,
      wasHelpOrWhereAmITriggeredAtLeastOnce: false,
      isRoboticVoice: !isChrome && isUsingMicrophone && soundLevel !== 'off'
    }
  }

  componentDidMount() {
    const { dispatch, user, lngCode, pageName, parentPage, grandParentPage, temporarilyUnlocked } =
      this.props
    const navigationCommands = [parentPage, grandParentPage, 'menu']
    startRecording(user)({ user, dispatch, navigationCommands, lngCode, temporarilyUnlocked })
    dispatch(prepareLearningAction({ pageName }))
  }

  shouldComponentUpdate(nextProps) {
    const {
      lngCode,
      path,
      parentPage,
      grandParentPage,
      isLearning,
      actual,
      isListening,
      isRoboticVoiceNoticeVisible
    } = this.props
    const {
      transcript,
      isLearning: nextIsLearning,
      actual: nextActual,
      isListening: nextIsListening
    } = nextProps
    const { isRoboticVoice } = this.state

    const navigationOptions = ['start', 'stop', parentPage, grandParentPage, 'menu']
    const translatedCommands = commonTranslateArray(navigationOptions)

    return (
      isTranscriptAMatch(transcript, translatedCommands) ||
      isRedirectButtonClick(path, navigationOptions) ||
      isLearning !== nextIsLearning ||
      actual !== nextActual ||
      isHelpRequest(transcript) ||
      isWhereAmIRequest(transcript, lngCode) ||
      (isRoboticVoice && isRoboticVoiceNoticeVisible && isListening !== nextIsListening)
    )
  }

  componentDidUpdate(prevProps) {
    const { actual: prevActual, isListening: prevIsListening } = prevProps
    const {
      dispatch,
      user,
      lngCode,
      language,
      gender,
      transcript,
      pageName,
      actual,
      translatedActual,
      extra,
      parentPage,
      grandParentPage,
      isListening,
      isRoboticVoiceNoticeVisible
    } = this.props
    const { isRoboticVoice } = this.state

    // nb: Saying "stop" doesnt work if isReadOutLoudDisabled = true as readOutLoud has resumeRecording callback
    if (isStopRequest(transcript) || !actual) {
      clearInterval(this.myIntervalRef.current)
    } else {
      if (actual && prevActual !== actual) {
        stopRecording(user)({ dispatch })
        const text = getRandomItemFromList(
          translatedLearning[pageName.replace(/famous_|other_/g, '')][lngCode](
            translatedActual,
            extra
          )
        )
        const navigationCommands = [parentPage, grandParentPage, 'menu']
        isReadOutLoudDisabled
          ? null
          : readOutLoud({ text, dispatch, language, gender, navigationCommands, user })
      }
    }

    if (isRoboticVoice && isRoboticVoiceNoticeVisible && prevIsListening && !isListening) {
      this.setState({ wasHelpOrWhereAmITriggeredAtLeastOnce: true })
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
    dispatch(resetLearningAction())
    clearInterval(this.myIntervalRef.current)
  }

  startLearning() {
    const { dispatch, lngCode, pageName } = this.props
    dispatch(startLearningAction())
    this.setState({ isStopClick: false })
    // first iterm being read right away, then intervals take over
    dispatch(updateLearningItemAction({ pageName, lngCode }))
    sendAmplitudeEvent('Learning Page Visits', {
      topic: getTopic(pageName),
      action: 'vocal'
    })

    this.myIntervalRef.current = setInterval(() => {
      const { transcript } = this.props
      const { isStopClick } = this.state
      if (isStopRequest(transcript) || isStopClick) {
        clearInterval(this.myIntervalRef.current)
        clearAllIntervals()
        dispatch(prepareLearningAction({ pageName }))
      } else {
        dispatch(updateLearningItemAction({ pageName, lngCode }))
      }
    }, intervalTime)
  }

  // for some reasons, this.myIntervalRef.current = setInterval(() => {works well with the voice
  // but not with the click, where a new name needs to be given.
  startLearningClick() {
    const { dispatch, lngCode, pageName } = this.props
    dispatch(startLearningAction())
    this.setState({ isStopClick: false })
    // first iterm being read right away, then intervals take over
    dispatch(updateLearningItemAction({ pageName, lngCode }))
    sendAmplitudeEvent('Learning Page Visits', {
      topic: getTopic(pageName),
      action: 'click'
    })

    const intervalStartClick = setInterval(() => {
      const { transcript } = this.props
      const { isStopClick } = this.state
      if (isStopRequest(transcript) || isStopClick) {
        clearInterval(intervalStartClick)
        clearAllIntervals()
        dispatch(prepareLearningAction({ pageName }))
      } else {
        dispatch(updateLearningItemAction({ pageName, lngCode }))
      }
    }, intervalTime)
  }

  stopLearningClick() {
    const { dispatch, user, lngCode, pageName, parentPage, grandParentPage, temporarilyUnlocked } =
      this.props
    this.setState({ isStopClick: true })
    clearInterval(this.myIntervalRef.current)
    dispatch(prepareLearningAction({ pageName }))
    // When readOutLoud is on, it records again with the onend callback; if it's dev disabled, we restart manually
    if (isReadOutLoudDisabled) {
      const navigationCommands = [parentPage, grandParentPage, 'menu']
      startRecording(user)({ user, dispatch, navigationCommands, lngCode, temporarilyUnlocked })
    }
  }

  render() {
    const {
      user,
      path,
      dispatch,
      pageName,
      parentPage,
      grandParentPage,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel,
      isLearning,
      actual,
      translatedActual,
      getImageUrl,
      randomNumber = 0,
      mapFillColorOne,
      mapFillColorTwo,
      isAnnyangSupported,
      isUsingMicrophone,
      isRoboticVoiceNoticeVisible
    } = this.props
    const { wasHelpOrWhereAmITriggeredAtLeastOnce } = this.state

    const translate = translateFrom(pageName)
    const title = isGeographyPage(pageName) ? translate('title') : common(pageName)

    const intro = translate('intro')
    const instructions = isUsingMicrophone
      ? [
          instructionTranslate('start'),
          instructionTranslate('stop'),
          ...[parentPage, grandParentPage].map(command =>
            instructionTranslate(`back_to_${command}`)
          ),
          instructionTranslate('menu')
        ]
      : []

    const text = `${intro} ${instructions}`
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const navigationCommands = [parentPage, grandParentPage, 'menu']
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? text : makeWhereIAmText[lngCode]({ title, isLearnPage: true }),
        dispatch,
        language,
        gender,
        clearTranscriptOnEnd: true,
        navigationCommands,
        user
      })
    }

    const isStart = isStartRequest(transcript)
    if (isStart) {
      dispatch(saveTranscriptAction([]))
      this.startLearning()
    }

    const mappedCommandpathNavigation = {
      [common(parentPage).toLowerCase()]:
        `/${grandParentPage.replace(/_/gi, '-')}/${parentPage.replace(/_/gi, '-')}`,
      [common(grandParentPage).toLowerCase()]: `/${grandParentPage.replace(/_/gi, '-')}`,
      [common('menu').toLowerCase()]: '/menu'
    }

    // Funky logic so the stop button border radius depends on a logc on the page name
    const multiplier = pageName.split('_')[1].length
    const createStopButtonRadius = multiplier =>
      `50%/${15 + (multiplier.length > 10 ? multiplier * 2 : multiplier * 5)}% ${
        70 - (multiplier % 2 === 0 ? multiplier : multiplier * 3)
      }% ${15 + multiplier * 3}%`

    return (
      <div className={`${pageName.replace(/_/g, '-')} top-navigation learn-page`}>
        {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

        {wasHelpOrWhereAmITriggeredAtLeastOnce && isRoboticVoiceNoticeVisible && (
          <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
        )}

        {isLearning ? (
          <>
            <div className="learn-page-title" style={{ color: getPageTitleColor(path) }}>
              {title}
            </div>
            <LearnDisplay
              pageName={pageName}
              actual={actual}
              translatedActual={translatedActual}
              getImageUrl={getImageUrl}
              randomNumber={randomNumber}
              mapFillColorOne={mapFillColorOne}
              mapFillColorTwo={mapFillColorTwo}
            />
            <div>
              <div
                style={{ borderRadius: createStopButtonRadius(multiplier) }}
                className="learn-page-stop-button-image"
                onClick={this.stopLearningClick}
                alt="learn-page-stop-button">
                <span>STOP</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="navigation-box" style={createBorderRadius(0.35)}>
              <PageHeader title={translate('title')} intro={intro} instructions={instructions} />

              <AppListeningSpeakingVisual />

              {!isAnnyangSupported && !isPaidUser(user) && (
                <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
              )}

              <NavigationButtons
                user={user}
                customNavigationCommands={mappedCommandpathNavigation}
                lngCode={lngCode}
                dispatch={dispatch}
                otherButton={
                  <button style={createBorderRadius(0.14)} onClick={this.startLearningClick}>
                    <span className="menu-button-icon-wrapper">
                      <ImageWithDimensions
                        alt="start icon"
                        className="menu-button-icon"
                        src={`${baseUrl}/images/icons/start.webp`}
                      />
                    </span>
                    <span className="menu-button-command-wrapper">
                      {capitalizeWord(common('start'))}
                    </span>
                  </button>
                }
              />
            </div>

            {!isNavigationSoundOff && (
              <SpeechButton
                className={`${pageName.replace(/_/g, '-')}-speech-button`}
                text={text}
                navigationCommands={navigationCommands}
              />
            )}

            {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
          </>
        )}
      </div>
    )
  }
}

export default connect(learnMapStateToProps, passDispatchToProps)(LearnPage)
