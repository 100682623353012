import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { common } from '../lib/languagesUtils/languageUtil'
import StyledI18n from './StyledI18n'

class LettersAndNumbersInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classStatus: 'none'
    }
  }

  componentTimeout

  componentDidUpdate(prevProps) {
    const { isPlaying: prevIsPlaying } = prevProps
    const { isPlaying } = this.props

    if (!prevIsPlaying && isPlaying) {
      this.componentTimeout = setTimeout(() => this.setState({ classStatus: 'show' }), 30000)
    }
    if (prevIsPlaying && !isPlaying) {
      this.setState({ classStatus: 'hide' })
    }
  }

  render() {
    const { isMath = false } = this.props
    const { classStatus } = this.state

    return (
      <div
        className={`letters-and-numbers-info-initial-state letters-and-numbers-info-${classStatus}`}>
        <div className="letters-and-numbers-info-lines-wrapper">
          <div className="letters-and-numbers-info-title">
            {common('letters_and_numbers_info_link_message_title')}
          </div>
          <div className="letters-and-numbers-info-section">
            {common(`${isMath ? '' : 'letters_and_'}numbers_info_link_message_intro`)}
          </div>
          {!isMath && (
            <div className="letters-and-numbers-info-section">
              <StyledI18n text={common('letters_and_numbers_info_link_message_letter')} />
            </div>
          )}
          <div className="letters-and-numbers-info-section">
            <StyledI18n
              text={common(`${isMath ? '' : 'letters_and_'}numbers_info_link_message_number`)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(LettersAndNumbersInfo)
