import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../database/firebase.config'
import '../styles/scss/App.scss'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import Notification from '../components/Notification'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import { Input, InputError } from '../components/InputComponents'
import NavigateLink from '../components/NavigateLink'
import { translateFrom, common, makeWhereIAmText } from '../lib/languagesUtils/languageUtil'
import { startRecording, stopRecording } from '../lib/speechToTextUtils/speechToText'
import {
  changePathAction,
  updateIsListeningAction,
  setUserAction,
  updateNotificationAction,
  clearNotificationAction
} from '../redux/actions/actions'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isLoggedUser,
  isPaidUser,
  isValidEmailFormat,
  createPageCustomNavigationCommands
} from '../lib/methodsUtil'
import { isReadOutLoudDisabled } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'

// SSS - on pages, Hide sign up link if a user is already logged
// - links to profile should be hidden if user is not logged in

const navigationCommands = ['menu']

const translate = translateFrom('sign_in_page')

class SignInPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      isLoading: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkForEmailError = this.checkForEmailError.bind(this)
    this.checkForPasswordError = this.checkForPasswordError.bind(this)
  }

  async componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    if (isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    } else {
      // SSS - the below allow to stay on this url and not navigate out when we get to this
      // {path !== '/sign-in' && <Navigate to={path} replace />}
      // we want to access this link via url when an email is confirmed
      dispatch(changePathAction('/sign-in'))
      dispatch(updateIsListeningAction(false))
      startRecording(user)({ user, dispatch, navigationCommands, lngCode })
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async checkForEmailError() {
    const { email } = this.state
    this.setState({
      emailError: !!email.length && !isValidEmailFormat(email) ? 'email_invalid' : ''
    })
  }

  checkForPasswordError() {
    const { password } = this.state
    this.setState({
      passwordError: password.length > 0 && password.length < 8 ? 'password_too_short' : ''
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { dispatch } = this.props
    const { email, password } = this.state
    this.setState({ isLoading: true })

    try {
      // Sign in the user
      const { user } = await signInWithEmailAndPassword(auth, email, password)

      const userDocRef = doc(db, 'users', user.uid)
      const userDoc = await getDoc(userDocRef)

      if (!userDoc.exists()) {
        dispatch(updateNotificationAction('Firebase: Error (storage/object-not-found).'))
        setTimeout(() => dispatch(clearNotificationAction()), 6000)
        return
      }

      let userData = userDoc.data()
      // if the email was verified but it wasn't updated in the users collection, we update it
      // as well as the user state (userData) that will be sent to the Redux state
      if (user.emailVerified && !userData.isEmailVerified) {
        await updateDoc(userDocRef, { isEmailVerified: true })
        userData.isEmailVerified = true
      }

      delete userData.userId
      dispatch(setUserAction(userData))
      dispatch(changePathAction('/profile'))
    } catch (error) {
      this.setState({ isLoading: false })
      dispatch(updateNotificationAction(error.message))
    } finally {
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
      this.setState({ isLoading: false })
    }
  }

  render() {
    const {
      user,
      path,
      soundLevel,
      language,
      lngCode,
      transcript,
      dispatch,
      isAnnyangSupported,
      notification
    } = this.props
    const { isLoading, email, emailError, password, passwordError } = this.state

    const disableSubmit =
      !email ||
      emailError !== '' ||
      !password ||
      passwordError !== '' ||
      (email && !isValidEmailFormat(email))

    const customNavigationCommands = createPageCustomNavigationCommands(navigationCommands)

    const intro = translate('intro')
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        // SSS - Test all the where am I and help
        text: isHelp ? intro : makeWhereIAmText[lngCode]({ title: common('sign_in') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    const commonInputProps = {
      onChange: this.handleInputChange,
      disabled: isLoading
    }

    return (
      <div className="sign-in-page top-navigation">
        <SEO
          description="E=MC² - Embrace the Mission and the call² - Have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} intro={intro} />

          <div className="sign-in-page_form_wrappers">
            <form
              className="form sign-in-page_manual_sign_in_wrapper"
              name="sign-in"
              onSubmit={this.handleSubmit}>
              <h2>{translate('get_back_to_account')}</h2>
              <div className="form-fields">
                <div className="form-column">
                  <div className="form-input-title">{common('email')}:</div>
                  <Input
                    name="email"
                    value={email}
                    {...commonInputProps}
                    type="email"
                    style={createBorderRadius(0.4, true)}
                    onBlur={this.checkForEmailError}
                  />
                  <InputError error={emailError} />

                  <div className="space-between">
                    <div className="form-input-title">{common('password')}:</div>
                    <NavigateLink
                      className="sign-in-page_forgot-password-link"
                      dispatch={dispatch}
                      navigateUrl="/forgot-password"
                      navigateText={`${common('forgot_password')} ?`}
                    />
                  </div>
                  <Input
                    name="password"
                    value={password}
                    {...commonInputProps}
                    type="password"
                    style={createBorderRadius(0.7)}
                    onBlur={this.checkForPasswordError}
                  />
                  <InputError error={passwordError} />
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="submit"
                  disabled={disableSubmit || isLoading}
                  className={disableSubmit || isLoading ? 'disabled' : ''}
                  style={createBorderRadius(0.9, true)}>
                  {common(isLoading ? 'logging' : 'let_s_go')}
                </button>
              </div>

              <div className="form-buttons sign-to-other-sign-button-wrapper space-between">
                <div className="sign-to-other-sign-message">{translate('no_account_yet')}</div>
                <NavigateLink
                  dispatch={dispatch}
                  navigateUrl="/sign-up"
                  navigateText={common('sign_up')}
                />
              </div>
            </form>
          </div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}
        </div>

        <Notification message={notification} />

        {path !== '/sign-in' && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(SignInPage))
