import React from 'react'
import { Trans } from 'react-i18next'
import NavigateLink from './NavigateLink'
import { common } from '../lib/languagesUtils/languageUtil'
import { baseUrl } from '../lib/_processUtil'

const createAnchor = (href, className) => (
  <a
    style={{ color: 'black' }}
    target="_blank"
    rel="noreferrer"
    href={href}
    className={className}
  />
)

const i18nextTransStyling = dispatch => ({
  italic: <i />,
  bold: <strong />,
  bolder: <span style={{ fontWeight: '800' }} />,
  red: <span style={{ color: 'red' }} />,
  blue: <span style={{ color: 'blue' }} />,
  green: <span style={{ color: 'green' }} />,
  orange: <span style={{ color: 'orange' }} />,
  chocolate: <span style={{ color: 'chocolate' }} />,
  grey: <span style={{ color: 'slategrey' }} />,
  crimson: <span style={{ color: 'crimson' }} />,
  instruction: <span style={{ textShadow: 'wheat 1px 0 10px', fontWeight: '600' }} />,
  br: <br />,
  freepik: createAnchor('http://www.freepik.com'),
  amcharts: createAnchor('https://www.amcharts.com/svg-maps'),
  wiki: createAnchor('https://www.wikipedia.org/'),
  terms: createAnchor(`${baseUrl}/terms-of-use`, 'form-link'),
  privacyPolicy: createAnchor(`${baseUrl}/privacy-policy`, 'form-link'),
  support: (
    <NavigateLink
      dispatch={dispatch}
      navigateUrl="/support"
      className="questions-page-navigate-to-support"
      navigateText={common('support_page')}
    />
  )
})

const StyledI18n = ({ text, values, dispatch = () => {}, className }) => (
  <div style={{ whiteSpace: 'pre-wrap' }} className={className}>
    <Trans defaults={text} components={i18nextTransStyling(dispatch)} values={values} />
  </div>
)

export default StyledI18n
