import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../styles/scss/App.scss'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import Notification from '../components/Notification'
import StyledI18n from '../components/StyledI18n'
import CheckBox from '../components/CheckBox'
// import NavigateLink from '../components/NavigateLink'
import { translateFrom, common } from '../lib/languagesUtils/languageUtil'
import { changePathAction, updateIsListeningAction } from '../redux/actions/actions'
import { createBorderRadius, isLoggedUser } from '../lib/methodsUtil'
import { decodeParam } from '../lib/emailUtil'

// SSS - useID in the url with a cypher?
// also a video in the email

const translate = translateFrom('parental_consent_page')

class ParentalConsent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wasApproved: false,
      isParentOfChild: false,
      hasGivenConsent: false,
      isTrueInformation: false
    }
    this.toggleIsParentOfChild = this.toggleIsParentOfChild.bind(this)
    this.toggleHasGivenConsent = this.toggleHasGivenConsent.bind(this)
    this.toggleIsTrueInformation = this.toggleIsTrueInformation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    // const { lngCode, user, dispatch } = this.props
    const { user, dispatch } = this.props

    // const search = window.location.search
    // if (!search) {
    //   dispatch(changePathAction('/'))
    // }
    // const myKey = search.slice(1)

    const myKey = 'NddEHIPphEfXcB6ejVpjtC5UUJz2'
    console.log('____', decodeParam(myKey))

    // NddEHIPphEfXcB6ejVpjtC5UUJz2
    // DXfKXVaxmIM43wo2SiHd3qvZ7Xo1
    // DU8n7edacQbwJzriUpaHc7I1MNp2

    // SSS - fetch user from firebase - user cypher to get userId
    // const userDocRef = doc(db, 'users', userId)
    // const userDoc = await getDoc(userDocRef)
    // const {email, parentEmail, hasParentalConsent} = userDoc

    const hasParentalConsent = false

    if (isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    } else if (hasParentalConsent) {
      dispatch(changePathAction('/'))
    } else {
      dispatch(changePathAction('/parental-consent'))
      dispatch(updateIsListeningAction(false))
    }
  }

  toggleIsParentOfChild() {
    this.setState({ isParentOfChild: !this.state.isParentOfChild })
  }

  toggleHasGivenConsent() {
    this.setState({ hasGivenConsent: !this.state.hasGivenConsent })
  }

  toggleIsTrueInformation() {
    this.setState({ isTrueInformation: !this.state.isTrueInformation })
  }

  async handleSubmit() {
    this.setState({ wasApproved: true })
    console.log('SSS will submit')
  }

  render() {
    // const { user, path, lngCode, notification } = this.props
    const { path, notification } = this.props
    const { wasApproved, isParentOfChild, hasGivenConsent, isTrueInformation } = this.state

    // SSS - need to come from cypher decoding
    const email = '1@1.com'
    const parentEmail = '2@2.com'

    const disableSubmit = !isParentOfChild || !hasGivenConsent || !isTrueInformation

    return (
      <div className="parental-consent-page top-navigation">
        <SEO
          description="E=MC² - Embrace the Mission and the call² - Have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} />

          <div className="profile-page-parent-email">
            <StyledI18n text={translate('message')} values={{ email, parentEmail }} />
          </div>

          {wasApproved ? (
            <div>the approval has been sent. click menu</div>
          ) : (
            <>
              <div className="video-wrapper">
                <h4>{translate('what_is')}</h4>
                <iframe
                  src="https://www.youtube.com/embed/uNNlTvviUZc"
                  title="Mini Einstein video presentation"
                />
              </div>

              <div className="parental-consent_form_wrappers">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-checkbox-wrapper">
                    <CheckBox
                      checkedValue={isParentOfChild}
                      onChange={this.toggleIsParentOfChild}
                      className="form-checkbox"
                      borderRadius={0.7}
                    />
                    {isParentOfChild && <div className="form-checkbox-checked">✔︎</div>}
                    <StyledI18n
                      text={translate('is_parent_of_child_message')}
                      values={{ email, parentEmail }}
                      className="parental-consent_checkbox-text"
                    />
                  </div>

                  <div className="sign-up-page_terms-and-policy form-checkbox-wrapper">
                    <CheckBox
                      checkedValue={hasGivenConsent}
                      name="hasGivenConsent"
                      onChange={this.toggleHasGivenConsent}
                      className="form-checkbox"
                      borderRadius={0.5}
                    />
                    {hasGivenConsent && <div className="form-checkbox-checked">✔︎</div>}
                    <StyledI18n
                      text={translate('has_given_consent_message')}
                      className="parental-consent_checkbox-text"
                    />
                  </div>

                  <div className="sign-up-page_terms-and-policy form-checkbox-wrapper">
                    <CheckBox
                      checkedValue={isTrueInformation}
                      name="isTrueInformation"
                      onChange={this.toggleIsTrueInformation}
                      className="form-checkbox"
                      borderRadius={0.27}
                    />
                    {isTrueInformation && <div className="form-checkbox-checked">✔︎</div>}
                    <StyledI18n
                      text={translate('is_true_information_message')}
                      className="parental-consent_checkbox-text"
                    />
                  </div>

                  <div className="form-buttons">
                    <button
                      type="submit"
                      disabled={disableSubmit || wasApproved}
                      className={disableSubmit || wasApproved ? 'disabled' : ''}
                      style={createBorderRadius(0.79, true)}>
                      {common('approve')}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>

        <Notification message={notification} />
      </div>
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, passDispatchToProps)(ParentalConsent)
)
