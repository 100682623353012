import React from 'react'
import { createBorderRadius } from '../lib/methodsUtil'

const CheckBox = ({
  name,
  labelText,
  checkedValue = false,
  onChange = e => {
    console.log(e)
  },
  isDisabled = false,
  className,
  borderRadius
}) => (
  <div className={className}>
    <input
      style={createBorderRadius(borderRadius)}
      type="checkbox"
      id={name}
      disabled={isDisabled}
      onChange={onChange}
      checked={checkedValue}
    />
    {labelText && (
      <label htmlFor={name} disabled={isDisabled}>
        {labelText}
      </label>
    )}
  </div>
)

export default CheckBox
