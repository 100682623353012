// import { wasPathChanged } from '../router/routerUtil'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import '../styles/scss/App.scss'
import { auth, db } from '../database/firebase.config'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import ImageWithDimensions from '../components/ImageWithDimensions'
import CheckBox from '../components/CheckBox'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import { translateFrom, common, makeWhereIAmText } from '../lib/languagesUtils/languageUtil'
import { startRecording, stopRecording } from '../lib/speechToTextUtils/speechToText'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction, updateIsListeningAction } from '../redux/actions/actions'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  createRoundedBorderRadius,
  isLoggedUser,
  isPaidUser,
  isValidEmailFormat,
  createPageCustomNavigationCommands
} from '../lib/methodsUtil'
import { isReadOutLoudDisabled, baseUrl } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'
import {
  userDataDefaultProperties,
  googleSignUp,
  facebookSignUp,
  appleSignUp,
  twitterSignUp,
  microsoftSignUp,
  filterProfanity
} from '../lib/signInUpUtil'

// SSS - Remake that page with the other one.

// TODO - Look into fixing SocialSignUp; and socialSignIn

const navigationCommands = ['menu']

const translate = translateFrom('sign_up_page')
const translateError = translateFrom('errors')

const defaultState = {
  username: '',
  usernameError: '',
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  passwordConfirm: '',
  passwordConfirmError: '',
  isAcceptedTermsAndPolicy: false,
  genericError: ''
}

class SocialSignUppPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: null,
      isSaving: false,
      ...defaultState
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
    this.checkForUsernameError = this.checkForUsernameError.bind(this)
    this.checkForEmailError = this.checkForEmailError.bind(this)
    this.checkForPasswordError = this.checkForPasswordError.bind(this)
    this.checkForPasswordConfirmError = this.checkForPasswordConfirmError.bind(this)
    this.toggleAcceptedTermsAndPolicy = this.toggleAcceptedTermsAndPolicy.bind(this)
  }

  componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    if (isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    } else {
      dispatch(updateIsListeningAction(false))
      startRecording(user)({ user, dispatch, navigationCommands, lngCode })
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    const targetName = e.target.name
    const targetValue = e.target.value
    this.setState({
      [targetName]: targetName === 'username' ? filterProfanity(targetValue) : targetValue
    })
  }

  toggleAcceptedTermsAndPolicy() {
    const { isAcceptedTermsAndPolicy } = this.state
    this.setState({ isAcceptedTermsAndPolicy: !isAcceptedTermsAndPolicy })
  }

  handleClearForm() {
    this.setState(defaultState)
  }

  async checkForUsernameError() {
    const { username } = this.state
    if (username && username.length > 30) {
      this.setState({ usernameError: 'username_too_long' })
    } else if (username && username.indexOf('*') > -1) {
      this.setState({ usernameError: 'username_with_profanity' })
    } else {
      const usernamesRef = doc(db, 'usernames', username)
      const usernameDoc = await getDoc(usernamesRef)
      this.setState({ usernameError: usernameDoc.exists() ? 'username_already_in_use' : '' })
    }
  }

  async checkForEmailError() {
    const { email } = this.state
    if (!!email.length && !isValidEmailFormat(email)) {
      this.setState({ emailError: 'email_invalid' })
    } else {
      const emailDocRef = doc(db, 'emails', email)
      const emailDoc = await getDoc(emailDocRef)
      this.setState({ emailError: emailDoc.exists() ? 'email_already_registered' : '' })
    }
  }

  checkForPasswordError() {
    const { password, passwordConfirm } = this.state
    this.setState({
      passwordError: password.length > 0 && password.length < 8 ? 'password_too_short' : ''
    })
    if (passwordConfirm) {
      this.setState({
        passwordConfirmError:
          password && passwordConfirm && password === passwordConfirm
            ? ''
            : 'password_confirm_error'
      })
    }
  }

  checkForPasswordConfirmError() {
    const { password, passwordConfirm } = this.state
    this.setState({
      passwordConfirmError:
        password && passwordConfirm && password === passwordConfirm ? '' : 'password_confirm_error'
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { username, email, password } = this.state
    this.setState({ isSaving: true })

    try {
      // Create the new user
      const { user } = await createUserWithEmailAndPassword(auth, email, password)

      const user_id = user.uid
      const userDataRef = doc(db, 'users', user_id)
      const userDoc = await getDoc(userDataRef)

      let userData
      if (!userDoc.exists()) {
        userData = {
          userId: user_id,
          username,
          email,
          ...userDataDefaultProperties
        }
        // adds in the usernames collection so no other user can use that username
        const usernamesRef = doc(db, 'usernames', username)
        await setDoc(usernamesRef, { username })

        // adds in the emails collection so no other user can use that email
        const emailRef = doc(db, 'emails', email)
        await setDoc(emailRef, { email })

        // adds the default data in the userData collection
        await setDoc(userDataRef, userData).then(() => {
          this.setState({ userData, isSaving: false })
        })
      }
    } catch (error) {
      this.setState({ genericError: error && error.message })
    }
  }

  render() {
    const { user, path, soundLevel, language, lngCode, transcript, dispatch, isAnnyangSupported } =
      this.props
    const {
      isSaving,
      username,
      usernameError,
      email,
      emailError,
      password,
      passwordError,
      passwordConfirm,
      passwordConfirmError,
      isAcceptedTermsAndPolicy,
      genericError
    } = this.state

    const disableSubmit =
      !username ||
      usernameError !== '' ||
      !email ||
      emailError !== '' ||
      !password ||
      passwordError !== '' ||
      !passwordConfirm ||
      passwordConfirmError !== '' ||
      !isAcceptedTermsAndPolicy ||
      genericError !== ''
    const disableClear =
      !username && !email && !password && !passwordConfirm && !isAcceptedTermsAndPolicy

    const customNavigationCommands = createPageCustomNavigationCommands(navigationCommands)

    const intro = translate('intro')
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? intro : makeWhereIAmText[lngCode]({ title: common('sign_up') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    const callback = async (userDataRef, userData) => {
      await setDoc(userDataRef, userData).then(() => {
        this.setState({ userData })
      })
    }

    const errorCallback = error => {
      this.setState({ genericError: error && error.message })
    }

    const emailAlreadyInUseCallback = () => {
      this.setState({ emailError: 'email_already_registered' })
    }

    return (
      <div className="sign-up-page top-navigation">
        <SEO
          description="E=MC² - Embrace the Mission and the call² - Have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} intro={intro} />

          <div className="sign-up-page_form_wrappers">
            <form
              className="form sign-up-page_manual_sign_up_wrapper"
              name="contact"
              onSubmit={this.handleSubmit}>
              <h2>Create An Account</h2>
              <div className="form-fields">
                <div className="form-column">
                  <div className="form-input-title">{common('username')}:</div>
                  <input
                    name="username"
                    type="text"
                    onChange={this.handleInputChange}
                    value={username}
                    style={createBorderRadius(0.1)}
                    className="form-md-input-field"
                    onBlur={this.checkForUsernameError}
                    disabled={isSaving}
                  />
                  {usernameError === 'username_already_in_use' ? (
                    <div className="form-field-error">
                      {translateError('username_already_registered')}
                    </div>
                  ) : usernameError === 'username_too_long' ? (
                    <div className="form-field-error">{translateError('username_too_long')}</div>
                  ) : usernameError === 'username_with_profanity' ? (
                    <div className="form-field-error">
                      {translateError('username_with_profanity')}
                    </div>
                  ) : null}
                  <div className="form-input-title">{common('email')}:</div>
                  <input
                    name="email"
                    type="email"
                    onChange={this.handleInputChange}
                    value={email}
                    style={createBorderRadius(0.4, true)}
                    className="form-md-input-field"
                    onBlur={this.checkForEmailError}
                    disabled={isSaving}
                  />
                  {emailError === 'email_invalid' && (
                    <div className="form-field-error">{translateError('email_invalid')}</div>
                  )}
                  {emailError === 'email_already_registered' && (
                    <div className="form-field-error">
                      {translateError('email_already_registered')}
                    </div>
                  )}
                  <div className="form-input-title">{common('password')}:</div>
                  <input
                    type="password"
                    name="password"
                    onChange={this.handleInputChange}
                    value={password}
                    style={createBorderRadius(0.7)}
                    className="form-md-input-field"
                    onBlur={this.checkForPasswordError}
                    disabled={isSaving}
                  />
                  {passwordError === 'password_too_short' && (
                    <div className="form-field-error">{translateError('password_too_short')}</div>
                  )}
                  <div className="form-input-title">{common('password_confirm')}:</div>
                  <input
                    name="passwordConfirm"
                    type="password"
                    onChange={this.handleInputChange}
                    value={passwordConfirm}
                    style={createBorderRadius(0.9, true)}
                    className="form-md-input-field"
                    onBlur={this.checkForPasswordConfirmError}
                    disabled={isSaving}
                  />
                  {passwordConfirmError && (
                    <div className="form-field-error">
                      {translateError('password_confirm_error')}
                    </div>
                  )}
                </div>
              </div>

              <div className="sign-up-page_terms-and-policy">
                <CheckBox
                  checkedValue={isAcceptedTermsAndPolicy}
                  onChange={isSaving ? () => {} : this.toggleAcceptedTermsAndPolicy}
                  className="form-checkbox"
                  disabled={isSaving}
                  borderRadius={0.7}
                />
                {isAcceptedTermsAndPolicy && <div className="form-checkbox-checked">✔︎</div>}
                <div className="sign-up-page_terms-and-policy-text">
                  <Trans
                    defaults={translate('terms_and_policy_acceptance')}
                    components={{
                      terms: (
                        <a className="form-link" href={`${baseUrl}/terms-of-use`} target="blank">
                          {common('terms')}
                        </a>
                      ),
                      policy: (
                        <a className="form-link" href={`${baseUrl}/privacy-policy`} target="blank">
                          {common('policy')}
                        </a>
                      )
                    }}
                  />
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="submit"
                  disabled={disableSubmit || isSaving}
                  className={disableSubmit || isSaving ? 'disabled' : ''}
                  style={createBorderRadius(0.9, true)}>
                  {common('send')}
                </button>
                <button
                  onClick={this.handleClearForm}
                  disabled={disableClear || isSaving}
                  className={disableClear || isSaving ? 'disabled' : ''}
                  style={createBorderRadius(0.1)}>
                  {common('clear')}
                </button>
              </div>
            </form>

            <div className="sign-up-page_sign_up_separator">
              <div className="sign-up-page_sign_up_separator-pre-line" />
              <h4 className="sign-up-page_sign_up_separator-or">or</h4>
              <div className="sign-up-page_sign_up_separator-line" />
            </div>

            <div className="form sign-up-page_social_sign_up_wrapper">
              <h2>Sign up with...</h2>
              <button
                className="social-sign-button"
                onClick={() => {
                  googleSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                }}
                style={createRoundedBorderRadius(0.7)}
                disabled={isSaving}>
                <ImageWithDimensions
                  alt="Google sign up"
                  src={`${baseUrl}/images/social-icons/google.svg`}
                />
                Google
              </button>

              <button
                className="social-sign-button"
                onClick={() => {
                  facebookSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                }}
                style={createRoundedBorderRadius(0.14, true)}
                disabled={isSaving}>
                <ImageWithDimensions
                  alt="Facebook sign up"
                  src={`${baseUrl}/images/social-icons/facebook.svg`}
                />
                Facebook
              </button>

              <button
                className="social-sign-button"
                onClick={() => {
                  twitterSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                }}
                style={createRoundedBorderRadius(0.52)}
                disabled={isSaving}>
                <ImageWithDimensions alt="X sign up" src={`${baseUrl}/images/social-icons/x.svg`} />
                X
              </button>

              <button
                className="social-sign-button"
                onClick={() => {
                  appleSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                }}
                style={createRoundedBorderRadius(0.95, true)}
                disabled={isSaving}>
                <ImageWithDimensions
                  alt="Apple sign up"
                  src={`${baseUrl}/images/social-icons/apple.svg`}
                />
                Apple
              </button>

              <button
                className="social-sign-button"
                onClick={() => {
                  microsoftSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                }}
                style={createRoundedBorderRadius(0.32)}
                disabled={isSaving}>
                <ImageWithDimensions
                  alt="Microsoft sign up"
                  className="microsoft-icon"
                  src={`${baseUrl}/images/social-icons/microsoft.svg`}
                />
                Microsoft
              </button>
            </div>
          </div>

          <div className="sign-up-page-hr"></div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}

          {path !== '/sign-up' && <Navigate to={path} replace />}
        </div>
      </div>
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, passDispatchToProps)(SocialSignUppPage)
)
