import React from 'react'
import { translateFrom } from '../lib/languagesUtils/languageUtil'
import { createBorderRadius } from '../lib/methodsUtil'
import { BounceIn, BounceOut, RubberBand, Shake } from '../lib/animationsUtil'

const successMessagesList = [
  'email_verification_successfully_sent',
  'parent_email_successfully_sent',
  'profile_created_successfully',
  'profile_updated_successfully',
  'profile_successfully_logged_out',
  'profile_successfully_deleted',
  'password_reset_successfully_sent'
]

const warningMessagesList = ['profile_creation_pending']

const firebaseErrorsList = [
  'Firebase: Error (auth/invalid-email).',
  'Firebase: Error (auth/user-disabled).',
  'Firebase: Error (auth/user-not-found).',
  'Firebase: Error (auth/wrong-password).',
  'Firebase: Error (auth/email-already-in-use).',
  'Firebase: Error (auth/requires-recent-login).',
  'Firebase: Error (auth/too-many-requests).',
  'Firebase: Error (auth/network-request-failed).',
  'Firebase: Error (auth/popup-blocked).',
  'Firebase: Error (auth/popup-closed-by-user).',
  'Firebase: Error (auth/credential-already-in-use).',
  'Firebase: Error (auth/operation-not-allowed).',
  'Firebase: Error (auth/invalid-verification-code).',
  'Firebase: Error (auth/invalid-verification-id).',
  'Firebase: Error (auth/missing-email).',
  'Firebase: Error (auth/invalid-action-code).',
  'Firebase: Error (auth/account-exists-with-different-credential).',
  'Firebase: Error (auth/invalid-credential).',
  'Firebase: Error (permission-denied).',
  'Firebase: Error (unavailable).',
  'Firebase: Error (cancelled).',
  'Firebase: Error (invalid-argument).',
  'Firebase: Error (not-found).',
  'Firebase: Error (resource-exhausted).',
  'Firebase: Error (already-exists).',
  'Firebase: Error (failed-precondition).',
  'Firebase: Error (aborted).',
  'Firebase: Error (out-of-range).',
  'Firebase: Error (data-loss).',
  'Firebase: Error (unauthenticated).',
  'Firebase: Error (storage/unknown).',
  'Firebase: Error (storage/object-not-found).',
  'Firebase: Error (storage/bucket-not-found).',
  'Firebase: Error (storage/project-not-found).',
  'Firebase: Error (storage/quota-exceeded).',
  'Firebase: Error (storage/unauthenticated).',
  'Firebase: Error (storage/unauthorized).',
  'Firebase: Error (storage/retry-limit-exceeded).',
  'Firebase: Error (storage/invalid-checksum).',
  'Firebase: Error (storage/canceled).',
  'Firebase: Error (storage/invalid-event-name).',
  'Firebase: Error (storage/invalid-url).',
  'Firebase: Error (storage/invalid-argument).',
  'Firebase: Error (storage/no-default-bucket).',
  'Firebase: Error (insufficient-permissions).'
]

const Notification = ({ message, className = '' }) => {
  const isSuccessMessage = successMessagesList.includes(message)
  const isWarningMessage = warningMessagesList.includes(message)
  const type = isSuccessMessage ? 'success' : isWarningMessage ? 'warning' : 'error'

  const notif = (
    <div
      className={`form-notification form-notification-${type} ${className}`}
      style={createBorderRadius(0.48, true)}>
      {firebaseErrorsList.includes(message)
        ? translateFrom('firebase_errors')(message.match(/\(([^)]+)\)/)[1].replaceAll('-', '_'))
        : isSuccessMessage
          ? translateFrom('success')(message)
          : isWarningMessage
            ? translateFrom('warning')(message)
            : message}
    </div>
  )

  return message ? (
    <BounceIn duration={0.25} className="form-notification-wrapper">
      <BounceOut delay={5.75} duration={0.25} className="form-notification-wrapper">
        {isSuccessMessage ? (
          <RubberBand delay={0.5} duration={5}>
            {notif}
          </RubberBand>
        ) : (
          <Shake delay={0.5} duration={5}>
            {notif}
          </Shake>
        )}
      </BounceOut>
    </BounceIn>
  ) : null
}

export default Notification
