import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const LettersAndNumbersPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['learn', 'play']}
    pageName="letters_and_numbers_page"
    customNavigationPath="/words/letters-and-numbers"
  />
)

export default LettersAndNumbersPage
