import { baseUrl } from '../../../lib/_processUtil'
import { letters, numbers } from '../../../lib/dataUtil'
import {
  isLetter,
  createSameKeyValueObject,
  createInvertedAnswerQuestionMap
} from '../../../lib/methodsUtil'
import { createInvertedKeysValuesObject } from '../../../lib/dataUtil'
import {
  its,
  oneAndTwoMap,
  supportedLngCodes,
  translatedQuestionsAndAnswers
} from '../../../lib/languagesUtils/languageUtil'
import { randomizeAWordAction, resetWordsAction } from '../../../redux/actions/actions'

const lettersAndNumbersList = [...letters, ...numbers]

const letterXList = letters.map(letter => `letter ${letter}`)

const letterLetterXsMap = createSameKeyValueObject(letters, 'letter')
const letterXLetterMap = createInvertedKeysValuesObject(letterLetterXsMap)

const numberNumberXMap = createSameKeyValueObject(numbers, 'number')
const numberXNumberMap = createInvertedKeysValuesObject(numberNumberXMap)

const questionAnswerMap = {
  en: {
    ...letterLetterXsMap,
    ...numberNumberXMap
  },
  fr: {
    ...createSameKeyValueObject(letters, 'lettre'),
    ...createSameKeyValueObject(numbers, 'chiffre')
  }
}

const answerQuestionMap = createInvertedAnswerQuestionMap(questionAnswerMap)

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.words.lettersAndNumbers

const makeQuestionText = (answer, lngCode = 'en') => questionText[lngCode](answer)

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const getLettersAndNumbersImageUrl = (letterOrNumber, randomNumber) => {
  return letterXList.includes(letterOrNumber)
    ? `${baseUrl}/images/words/letters/${letterXLetterMap[letterOrNumber]}/${randomNumber}.webp`
    : `${baseUrl}/images/mathematics/numbers/${randomNumber}/${numberXNumberMap[letterOrNumber]}.webp`
}

const getLettersAndNumbersLearnImageUrl = (letterOrNumber, randomNumber) => {
  return isLetter(letterOrNumber)
    ? `${baseUrl}/images/words/letters/${letterOrNumber}/${randomNumber}.webp`
    : `${baseUrl}/images/mathematics/numbers/${randomNumber}/${letterOrNumber}.webp`
}

const commonProps = {
  navigationCommands: ['words', 'menu'],
  imageAlt: 'letters-and-numbers',
  randomizeQuestionFunction: randomizeAWordAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetWordsAction,
  displayType: 'image',
  getImageUrl: getLettersAndNumbersImageUrl
}

let alsoAcceptedAsAnswersMap = {}
supportedLngCodes.forEach(lng => {
  alsoAcceptedAsAnswersMap[lng] = {}
  Object.keys(questionAnswerMap[lng]).forEach(letterOrNumber => {
    alsoAcceptedAsAnswersMap[lng][questionAnswerMap[lng][letterOrNumber]] = [
      '0',
      '1',
      '2',
      'E',
      'L',
      'U',
      'Y'
    ].includes(letterOrNumber)
      ? [`${its(lng)} ${letterOrNumber}`, ...oneAndTwoMap[lng][letterOrNumber]]
      : [`${its(lng)} ${letterOrNumber}`]
  })
})

const gameTypeProps = ({ lngCode, questionType, letterOrNumber }) => {
  return {
    pageName: `${questionType}_page`,
    questionText: makeQuestionText(letterOrNumber, lngCode),
    questionAnswer: makeQuestionAnswer(letterOrNumber, lngCode),
    answerQuestionMap,
    alsoAcceptedAsAnswers: alsoAcceptedAsAnswersMap[lngCode]
  }
}

export {
  letters,
  lettersAndNumbersList,
  questionAnswerMap,
  answerQuestionMap,
  commonProps,
  gameTypeProps,
  getLettersAndNumbersLearnImageUrl
}
