import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const Content = translate => <div>{translate('some_content')}</div>

const AchievementsPage = () => (
  <TopicNavigationPage
    navigationCommands={['about']}
    pageName="achievements_page"
    borderRadius={0.32}
    SEODescription="E=MC² - ">
    {Content}
  </TopicNavigationPage>
)

export default AchievementsPage
