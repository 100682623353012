import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../database/firebase.config'
import '../styles/scss/App.scss'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import Notification from '../components/Notification'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import { Input, InputError } from '../components/InputComponents'
import { translateFrom, common, makeWhereIAmText } from '../lib/languagesUtils/languageUtil'
import { startRecording, stopRecording } from '../lib/speechToTextUtils/speechToText'
import {
  changePathAction,
  updateIsListeningAction,
  updateNotificationAction,
  clearNotificationAction
} from '../redux/actions/actions'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isLoggedUser,
  isPaidUser,
  isValidEmailFormat,
  createPageCustomNavigationCommands
} from '../lib/methodsUtil'
import { isReadOutLoudDisabled } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'

const navigationCommands = ['menu']

const translate = translateFrom('forgot_password_page')

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailError: '',
      isSendingEmail: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkForEmailError = this.checkForEmailError.bind(this)
  }

  async componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    if (isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    } else {
      dispatch(updateIsListeningAction(false))
      startRecording(user)({ user, dispatch, navigationCommands, lngCode })
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async checkForEmailError() {
    const { email } = this.state
    this.setState({
      emailError: !!email.length && !isValidEmailFormat(email) ? 'email_invalid' : ''
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { dispatch } = this.props
    const { email } = this.state
    this.setState({ isSendingEmail: true })

    // by the way, if the user didn't confirm her/his email, when the password reset link
    // is sent and clicked, firebase automatically confirms the email. The "users" collection
    // is updated in the sign in page
    try {
      // Redirect URL after password reset
      await sendPasswordResetEmail(auth, email, { url: 'http://localhost:8888/sign-in' })
      dispatch(updateNotificationAction('password_reset_successfully_sent'))
    } catch (error) {
      this.setState({ isSendingEmail: false })
      dispatch(updateNotificationAction(error.message))
    } finally {
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
      this.setState({ isSendingEmail: false })
    }
  }

  render() {
    const {
      user,
      path,
      soundLevel,
      language,
      lngCode,
      transcript,
      dispatch,
      isAnnyangSupported,
      notification
    } = this.props
    const { isSendingEmail, email, emailError } = this.state

    const disableSubmit = !email || emailError !== '' || (email && !isValidEmailFormat(email))

    const customNavigationCommands = createPageCustomNavigationCommands(navigationCommands)

    const intro = translate('intro')
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? intro : makeWhereIAmText[lngCode]({ title: common('forgot_password') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    const commonInputProps = {
      onChange: this.handleInputChange,
      disabled: isSendingEmail
    }

    return (
      <div className="forgot-password-page top-navigation">
        <SEO
          description="E=MC² - Embrace the Mission and the call² - Have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} />
          <div>{translate('intro')}</div>
          <div className="forgot-password-page-description">{translate('description')}</div>

          <div className="sign-in-page_form_wrappers">
            <form
              className="form forgot_password_form_wrapper"
              name="forgot-password"
              onSubmit={this.handleSubmit}>
              <div className="form-fields">
                <div className="form-column">
                  <div className="form-input-title">{common('email')}:</div>
                  <Input
                    name="email"
                    value={email}
                    {...commonInputProps}
                    type="email"
                    style={createBorderRadius(0.4, true)}
                    onBlur={this.checkForEmailError}
                  />
                  <InputError error={emailError} />
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="submit"
                  disabled={disableSubmit || isSendingEmail}
                  className={disableSubmit || isSendingEmail ? 'disabled' : ''}
                  style={createBorderRadius(0.9, true)}>
                  {common(isSendingEmail ? 'sending' : 'send')}
                </button>
              </div>
            </form>
          </div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}
        </div>

        <Notification message={notification} />

        {path !== '/forgot-password' && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, passDispatchToProps)(ForgotPasswordPage)
)
