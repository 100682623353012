import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { deleteUser, sendEmailVerification, getAuth, signOut } from 'firebase/auth'
import { doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../database/firebase.config'
import '../styles/scss/App.scss'
import { wasPathChanged } from '../router/routerUtil'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import FreeVersionBanner from '../components/FreeVersionBanner'
import Select from '../components/Select'
import StyledI18n from '../components/StyledI18n'
import CheckBox from '../components/CheckBox'
import Notification from '../components/Notification'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { Input, InputError } from '../components/InputComponents'
import Modal from '../components/Modal'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isLoggedUser,
  isConfirmedLoggedUser,
  isPaidUser,
  createPageCustomNavigationCommands,
  isValidEmailFormat
} from '../lib/methodsUtil'
import {
  startRecording,
  stopRecording,
  isUsingAnnyang
} from '../lib/speechToTextUtils/speechToText'
import { createEmailHTML } from '../lib/emailUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import {
  translateFrom,
  common,
  makeWhereIAmText,
  createStringWithAndForLastItemInList
} from '../lib/languagesUtils/languageUtil'
import { getPluralLetter, getPluralCanVerb, frLeLaPrefix } from '../lib/languagesUtils/frenchUtil'
// import { wasPathChanged } from '../router/routerUtil'
// import { changePathAction, updateIsListeningAction, getUserAction } from '../redux/actions/actions'
import {
  changePathAction,
  setUserAction,
  updateNotificationAction,
  clearNotificationAction
} from '../redux/actions/actions'
import { filterProfanity } from '../lib/signInUpUtil'
import 'react-datepicker/dist/react-datepicker.css'
import { baseUrl, isReadOutLoudDisabled } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'
import {
  languageCountriesList,
  countryNamesTranslations
} from './geography/flags/answerQuestionMap'
import { getCountryFlagImageUrl } from './geography/flags/util'

// LATER {common('free_trial')} should open the free trial popup to say its
// a 15min trial instead of redirect to shop like it does now.

const navigationCommands = ['questions', 'about', 'menu']

const translate = translateFrom('profile_page')

const defaultState = {
  profileFirstName: '',
  profileFirstNameError: '',
  profileLastName: '',
  profileLastNameError: '',
  profileNationality: '',
  profileCountry: '',
  profileCity: '',
  profileCityError: '',
  profileIsPublic: false,
  notificationMessage: null
}

class ProfilePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: null,
      isSaving: false,
      wasEmailSent: false,
      parentEmail: '',
      parentEmailError: '',
      ...defaultState
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.validateString = this.validateString.bind(this)
    this.validateProfanity = this.validateProfanity.bind(this)
    this.sendEmailVerification = this.sendEmailVerification.bind(this)
    this.sendParentEmail = this.sendParentEmail.bind(this)
    this.checkForParentEmailError = this.checkForParentEmailError.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.togglePublicProfileAcceptance = this.togglePublicProfileAcceptance.bind(this)
    this.logoutUser = this.logoutUser.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
  }

  componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    if (isLoggedUser(user)) {
      const { nationality, country, city, isPublic } = user

      startRecording(user)({ user, dispatch, navigationCommands, lngCode })

      // these 4 datas can be changed multiple times so we assign them to profileValues by default on page Mount
      if (nationality) {
        this.setState({ profileNationality: nationality })
      }
      if (country) {
        this.setState({ profileCountry: country })
      }
      if (city) {
        this.setState({ profileCity: city })
      }
      if (isPublic) {
        this.setState({ profileIsPublic: isPublic })
      }
    } else {
      dispatch(changePathAction('/'))
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    const targetName = e.target.name
    const targetValue = e.target.value
    this.setState({
      [targetName]: ['profileFirstName', 'profileLastName', 'profileCity'].includes(targetName)
        ? filterProfanity(targetValue)
        : targetValue
    })
  }

  togglePublicProfileAcceptance() {
    const { profileIsPublic } = this.state
    this.setState({ profileIsPublic: !profileIsPublic })
  }

  validateString(stringToCheck, fieldNameError) {
    if (stringToCheck && stringToCheck.indexOf('*') > -1) {
      this.setState({ [fieldNameError]: 'has_profanity' })
    } else if (stringToCheck && stringToCheck.length > 30) {
      this.setState({ [fieldNameError]: 'too_long' })
    } else if (stringToCheck && stringToCheck.length < 2) {
      this.setState({ [fieldNameError]: 'too_short' })
    } else {
      this.setState({ [fieldNameError]: '' })
    }
  }

  validateProfanity(stringToCheck, fieldNameError) {
    if (stringToCheck && stringToCheck.indexOf('*') > -1) {
      this.setState({ [fieldNameError]: 'has_profanity' })
    } else {
      this.setState({ [fieldNameError]: this.state[fieldNameError] })
    }
  }

  async sendEmailVerification() {
    const auth = getAuth()
    const user = auth.currentUser
    const { dispatch } = this.props

    try {
      await sendEmailVerification(user, {
        // TODO - url value needs to be updated before release - Test with https://10minutemail.com/
        // it's where the user is redirected once the email verification has been clicked.
        // nb: this link need to be added to Firebase authentication Settings domains
        // url: `${baseUrl}/sign-in`,
        url: 'http://localhost:8888/sign-in',
        handleCodeInApp: true
      })
      this.setState({ wasEmailSent: true })
      dispatch(updateNotificationAction('email_verification_successfully_sent'))
    } catch (error) {
      dispatch(updateNotificationAction(error.message))
    } finally {
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
    }
  }

  async sendParentEmail() {
    // TODO - in the future, ask for child's name to send in the title email and content
    const { lngCode, user, dispatch } = this.props
    const { email } = user
    const { parentEmail } = this.state
    // const authUser = auth.currentUser
    const emailDocRef = doc(db, 'emails', email)
    const emailDoc = await getDoc(emailDocRef)

    if (!emailDoc.exists()) return
    const userId = emailDoc.data().userId
    // user cipher

    // const userId = emailDoc.data().userId

    // to be replaced by my real domain when release
    const mailgunDomain = 'sandboxf0582bf0f2fe4a589ff8d3ba0df79671.mailgun.org'
    // const fromMiniEinsteinEmail = "mini-einstein.com <no-reply@mini-einstein.com>"
    const fromMiniEinsteinEmail = 'my fake site <no-reply@my-fake-site.com>'

    const emailData = new URLSearchParams()
    emailData.append('from', fromMiniEinsteinEmail)
    emailData.append('to', parentEmail)
    emailData.append('subject', `Request on behalf of ${email}`)
    // SSS - language
    emailData.append('html', createEmailHTML(lngCode, email, userId))

    try {
      await axios.post(`https://api.mailgun.net/v3/${mailgunDomain}/messages`, emailData, {
        auth: {
          username: 'api', // Mailgun uses 'api' as the username
          password: process.env.REACT_APP_MAILGUN_API_KEY
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      this.setState({ wasEmailSent: true })
      dispatch(updateNotificationAction('parent_email_successfully_sent'))
    } catch (error) {
      this.setState({ wasEmailSent: false })
      dispatch(updateNotificationAction(error.message))
    } finally {
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
    }
  }

  async checkForParentEmailError() {
    const { parentEmail } = this.state
    if (!!parentEmail.length && !isValidEmailFormat(parentEmail)) {
      this.setState({ parentEmailError: 'email_invalid' })
    } else {
      this.setState({ parentEmailError: '' })
    }
  }

  async logoutUser() {
    const auth = getAuth()
    const { dispatch } = this.props
    try {
      await signOut(auth)
      // remove the user from the redux state, redirect to home, show success message and hides it.
      dispatch(setUserAction({}))
      dispatch(changePathAction('/'))
      dispatch(updateNotificationAction('profile_successfully_logged_out'))
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
    } catch (error) {
      dispatch(updateNotificationAction(error.message))
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
    }
  }

  handleClearForm() {
    const { user } = this.props
    this.setState({
      profileFirstName: user.firstName,
      profileFirstNameError: '',
      profileLastNameError: '',
      profileLastName: user.lastName,
      profileNationality: user.nationality,
      profileCountry: user.country,
      profileCity: user.city,
      profileCityError: '',
      profileIsPublic: user.isPublic,
      isSaving: false,
      wasEmailSent: true,
      notificationMessage: ''
    })
  }

  async saveChanges(e) {
    e.preventDefault()
    const { user, dispatch } = this.props
    const { email } = user
    const {
      profileFirstName,
      profileLastName,
      profileNationality,
      profileCountry,
      profileCity,
      profileIsPublic
    } = this.state
    this.setState({ isSaving: true })

    try {
      const emailDocRef = doc(db, 'emails', email)
      const emailDoc = await getDoc(emailDocRef)

      if (!emailDoc.exists()) return

      const userId = emailDoc.data().userId
      const userDocRef = doc(db, 'users', userId)

      const userDoc = await getDoc(userDocRef)

      const oneTimeProperties = {
        firstName: profileFirstName,
        lastName: profileLastName
      }

      const multipleTimesProperties = {
        nationality: profileNationality,
        country: profileCountry,
        city: profileCity,
        isPublic: profileIsPublic
      }

      const newData = {}
      for (let key in oneTimeProperties) {
        if (oneTimeProperties[key]) {
          newData[key] = oneTimeProperties[key]
        }
      }
      for (let key in multipleTimesProperties) {
        const newVal = multipleTimesProperties[key]
        if (newVal && newVal !== user[key]) {
          newData[key] = newVal
        }
      }

      const userData = {
        ...user,
        ...newData
      }

      if (Object.keys(newData).length === 0) return

      if (userDoc.exists()) {
        await updateDoc(userDocRef, newData)
        this.setState({ isSaving: false })
        dispatch(setUserAction(userData))
        dispatch(updateNotificationAction('profile_updated_successfully'))
        setTimeout(() => {
          this.setState({
            userData,
            isSaving: false,
            wasEmailSent: true
          })
          dispatch(clearNotificationAction())
        }, 6000)
      }
    } catch (error) {
      dispatch(updateNotificationAction(error.message))
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
    }
  }

  async deleteUser(e) {
    e.preventDefault()
    const { user, dispatch } = this.props
    const { email } = user
    this.setState({ isSaving: true })

    try {
      const emailDocRef = doc(db, 'emails', email)
      const emailDoc = await getDoc(emailDocRef)

      if (!emailDoc.exists()) return

      const userId = emailDoc.data().userId
      const userDocRef = doc(db, 'users', userId)

      const authUser = auth.currentUser

      if (authUser && authUser.email === email) {
        await deleteDoc(emailDocRef)
        await deleteDoc(userDocRef)
        await deleteUser(authUser)
        this.setState({ isSaving: false })
        // remove the user from the redux state
        dispatch(setUserAction({}))
        dispatch(updateNotificationAction('profile_successfully_deleted'))
        setTimeout(() => dispatch(clearNotificationAction()), 6000)
        dispatch(changePathAction('/'))
      }
    } catch (error) {
      dispatch(updateNotificationAction(error.message))
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
    }
  }

  render() {
    const {
      dispatch,
      path,
      user,
      soundLevel,
      language,
      lngCode,
      transcript,
      isAnnyangSupported,
      notification
    } = this.props
    const {
      isEmailVerified,
      email,
      firstName,
      lastName,
      dob,
      nationality,
      country,
      city,
      isPublic,
      plan,
      creationDate,
      hasUsedFreeTrial
    } = user
    const {
      isSaving,
      profileFirstName,
      profileFirstNameError,
      profileLastName,
      profileLastNameError,
      profileNationality,
      profileCountry,
      profileCity,
      profileCityError,
      profileIsPublic,
      wasEmailSent,
      notificationMessage,
      parentEmail,
      parentEmailError
    } = this.state
    const isCreatedLessThan24HoursAgo = Date.now() - creationDate < 24 * 60 * 60 * 1000
    const age = moment().diff(dob, 'years')

    const isMandatoryData = email && firstName && lastName
    const isProfileDataComplete = isMandatoryData && nationality && country && city

    const isMissingProfileData = !firstName || !lastName || !nationality || !country || !city
    const hasIsPublicError =
      profileIsPublic &&
      ((firstName ? false : profileFirstName.length === 0) ||
        (lastName ? false : profileLastName.length === 0) ||
        profileNationality.length === 0 ||
        profileCountry.length === 0 ||
        profileCity.length === 0)

    const hasNoDiffProfile =
      (firstName ? true : !profileFirstName) &&
      (lastName ? true : !profileLastName) &&
      profileNationality === nationality &&
      profileCountry === country &&
      profileCity === city &&
      profileIsPublic === isPublic

    const isClearDisabled = hasNoDiffProfile

    const hasError = profileFirstNameError || profileLastNameError || profileCityError
    const isSaveDisabled = hasNoDiffProfile || hasError || hasIsPublicError

    const isParentEmailButtonDisabled =
      wasEmailSent ||
      !parentEmail ||
      (parentEmail.length && !isValidEmailFormat(parentEmail)) ||
      parentEmailError

    const customNavigationCommands = createPageCustomNavigationCommands(navigationCommands)

    const intro = translate('intro')
    const description = translate('description')
    const text = `${intro}. ${description}`
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? text : makeWhereIAmText[lngCode]({ title: common('profile') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    const commonInputProps = {
      type: 'text',
      onChange: this.handleInputChange,
      className: 'form-md-input-field',
      disabled: isSaving
    }

    let missingNames = [common('first_name'), common('last_name')]
    if (lngCode === 'fr') {
      missingNames = missingNames.map(mn => frLeLaPrefix(mn.toLowerCase()))
    }
    const missingArray = [firstName, lastName]
      .map((property, i) => (property ? null : missingNames[i]))
      .filter(p => !!p)
    let missingProperties = createStringWithAndForLastItemInList(
      missingArray,
      lngCode
    ).toLowerCase()
    if (lngCode === 'en' && missingArray.length > 0) {
      missingProperties = `the ${missingProperties}`
    }

    /* optional - Birthday,  This is to wish birthdays */
    const profileSecondaryNames = ['age', 'nationality', 'country', 'city']
    const profileSecondaryData = [dob, nationality, country, city]
      .map((data, index) =>
        data ? (
          <div key={index} className="profile-grid-span-5">
            <div className="profile-info-section-title">
              {common(profileSecondaryNames[index])}:
            </div>
            <div className="profile-info-section-value profile-value">
              {index === 0
                ? `${age} ${common(age > 1 ? 'years_old' : 'year_old')}`
                : [1, 2].includes(index) && lngCode !== 'en'
                  ? countryNamesTranslations[lngCode][data]
                  : data}
            </div>
          </div>
        ) : null
      )
      .filter(data => data)

    const saveModalButtonAndTitle = translate(
      isProfileDataComplete ? 'update_profile' : 'complete_profile'
    )

    const saveProfileModal = (
      <Modal
        activatorButtonText={saveModalButtonAndTitle}
        activatorButtonClass={
          isProfileDataComplete ? 'profile-page-update-button' : 'profile-page-save-button'
        }
        style={createBorderRadius(0.26)}>
        <div className="profile-page-save-modal-wrapper" style={createBorderRadius(0.26)}>
          <PageHeader title={saveModalButtonAndTitle} />
          <div className="profile-page-save-modal-body profile-grid">
            {!!missingProperties.length && (
              <div className="profile-grid-span-10">
                <StyledI18n
                  text={translate('names_and_dob_warning')}
                  values={{
                    missingProperties,
                    pluralLetter: getPluralLetter(lngCode, missingArray),
                    pluralCanVerb: getPluralCanVerb(lngCode, missingArray)
                  }}
                />
              </div>
            )}

            {!firstName && (
              <>
                <div className="profile-grid-span-2">{common('first_name')}:</div>
                <div className="profile-grid-span-8">
                  <Input
                    name="profileFirstName"
                    value={profileFirstName}
                    {...commonInputProps}
                    style={createBorderRadius(0.1)}
                    onBlur={() => this.validateString(profileFirstName, 'profileFirstNameError')}
                    onKeyUp={() =>
                      this.validateProfanity(profileFirstName, 'profileFirstNameError')
                    }
                  />
                </div>
                {profileFirstNameError && (
                  <>
                    <div className="profile-grid-span-2" />
                  </>
                )}
                <InputError error={profileFirstNameError} className="profile-grid-span-8" />
              </>
            )}

            {!lastName && (
              <>
                <div className="profile-grid-span-2">{common('last_name')}:</div>
                <div className="profile-grid-span-8">
                  <Input
                    name="profileLastName"
                    value={profileLastName}
                    {...commonInputProps}
                    style={createBorderRadius(0.1)}
                    onBlur={() => this.validateString(profileLastName, 'profileLastNameError')}
                    onKeyUp={() => this.validateProfanity(profileLastName, 'profileLastNameError')}
                  />
                </div>
                {profileLastNameError && (
                  <>
                    <div className="profile-grid-span-2" />
                  </>
                )}
                <InputError error={profileLastNameError} className="profile-grid-span-8" />
              </>
            )}

            <div className="profile-grid-span-2">{common('nationality')}:</div>
            <div className="profile-grid-span-3 profile-grid-mobile-8">
              <Select
                name="profileNationality"
                value={profileNationality}
                optionPlaceholderText={translate('select_nationality_placeholder')}
                optionsList={languageCountriesList[lngCode]}
                optionsEnList={languageCountriesList.en}
                onChange={this.handleInputChange}
                style={createBorderRadius(0.883, true)}
                className="form-md-input-field form-md-select-field"
                disabled={isSaving}
              />
            </div>

            <div className="profile-grid-span-2">{common('country')}:</div>
            <div className="profile-grid-span-3 profile-grid-mobile-8">
              <Select
                name="profileCountry"
                value={profileCountry}
                optionPlaceholderText={translate('select_country_placeholder')}
                optionsList={languageCountriesList[lngCode]}
                optionsEnList={languageCountriesList.en}
                onChange={this.handleInputChange}
                style={createBorderRadius(0.33)}
                className="form-md-input-field form-md-select-field"
                disabled={isSaving}
              />
            </div>

            <div className="profile-grid-span-2">{common('city')}:</div>
            <div className={`profile-grid-span-${dob ? '8' : '3'} profile-grid-mobile-8`}>
              <Input
                name="profileCity"
                value={profileCity}
                {...commonInputProps}
                style={createBorderRadius(0.94)}
                onBlur={() => this.validateString(profileCity, 'profileCityError')}
                onKeyUp={() => this.validateProfanity(profileCity, 'profileCityError')}
              />
            </div>
            {profileCityError && (
              <>
                <div className={`profile-grid-span-${dob ? '2' : '7'} profile-grid-mobile-2`} />
              </>
            )}
            <InputError
              error={profileCityError}
              className={`profile-grid-span-${dob ? '8' : '3'} profile-grid-mobile-8`}
            />

            <div className="profile-grid-span-1 form-checkbox-wrapper">
              <CheckBox
                checkedValue={profileIsPublic}
                onChange={isSaving ? () => {} : this.togglePublicProfileAcceptance}
                className="form-checkbox"
                isDisabled={isSaving}
                borderRadius={0.47}
              />
              {profileIsPublic && <div className="form-checkbox-checked">✔︎</div>}
            </div>
            <div className="profile-grid-span-9">{translate('public_profile_consent')}</div>
            {hasIsPublicError && (
              <>
                <div className="profile-grid-span-1"></div>
                <InputError error="public_profile_checkbox" className="profile-grid-span-9" />
              </>
            )}

            <button
              className={`profile-page-update-button profile-page-modal-button ${
                isClearDisabled ? 'profile-button-disabled' : ''
              }`}
              style={createBorderRadius(0.35, true)}
              onClick={isClearDisabled ? () => {} : this.handleClearForm}
              disabled={isClearDisabled}>
              {translate('save_modal_undo_button_text')}
            </button>

            <button
              className={`profile-page-save-button profile-page-modal-button ${isSaveDisabled ? 'profile-button-disabled' : ''}`}
              style={createBorderRadius(0.86)}
              onClick={isSaveDisabled ? () => {} : this.saveChanges}
              disabled={isSaveDisabled}>
              {translate('save_modal_button_text')}
            </button>
          </div>
        </div>
      </Modal>
    )

    return email ? (
      <div className="profile-page top-navigation">
        <SEO
          description="E=MC² - Edit, Meet and Connect - Update your profile to have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box" style={createBorderRadius(0.5, true)}>
          <PageHeader title={translate('title')} />

          <div className="profile-page-welcome-message">
            {isCreatedLessThan24HoursAgo ? (
              translate('welcome_message')
            ) : isEmailVerified ? (
              <button
                style={createBorderRadius(0.1, true)}
                className="profile-page-go-button"
                onClick={() => dispatch(changePathAction('/menu'))}>
                {common('let_s_go')}
              </button>
            ) : null}
          </div>

          {age < 16 ? (
            <div className="profile-page-parent-email">
              <StyledI18n
                text={translate(
                  wasEmailSent ? 'parent_email_sent_message' : 'parent_email_message'
                )}
                values={{ parentEmail }}
              />
              {wasEmailSent ? (
                <div>
                  <button
                    style={createBorderRadius(0.33, true)}
                    className="profile-page-go-button"
                    onClick={() => dispatch(changePathAction('/menu'))}>
                    {common('menu')}
                  </button>
                </div>
              ) : (
                <>
                  <div className="profile-page-parent-email-wrapper">
                    <div className="form-input-title">{translate('parent_email')}:</div>
                    <Input
                      name="parentEmail"
                      value={parentEmail}
                      {...commonInputProps}
                      type="email"
                      style={createBorderRadius(0.63, true)}
                      onBlur={this.checkForParentEmailError}
                      onKeyUp={parentEmailError ? this.checkForParentEmailError : () => {}}
                    />
                    <InputError error={parentEmailError} />
                  </div>
                  <button
                    className={`profile-page-save-button ${isParentEmailButtonDisabled ? 'profile-page-save-button-disabled' : ''}`}
                    style={createBorderRadius(0.48)}
                    onClick={this.sendParentEmail}
                    disabled={isParentEmailButtonDisabled}>
                    {translate('parent_email_button_text')}
                  </button>
                </>
              )}
            </div>
          ) : isEmailVerified ? (
            <>
              {isConfirmedLoggedUser(user) && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    padding: '5px',
                    background: 'pink',
                    border: '1px solid black',
                    borderRadius: '4px',
                    margin: '10px'
                  }}>
                  {user.name} logged in! 👍
                </div>
              )}

              <div className="profile-page-content-wrapper">
                <h4 className="profile-page-title-info">{common('info')}</h4>

                <div className="profile-grid">
                  {isMandatoryData && (
                    <ImageWithDimensions
                      alt="user profile image"
                      className="profile-picture"
                      src={
                        nationality
                          ? getCountryFlagImageUrl(nationality)
                          : `${baseUrl}/images/mini-einstein-menu-image.webp`
                      }
                      style={createBorderRadius(0.48, true)}
                    />
                  )}

                  <div className="profile-grid-span-2">{common('email')}:</div>
                  <div
                    className={`profile-grid-span-8 profile-value profile-value-ellipsis ${isMandatoryData ? 'profile-value-with-margin-right' : ''}`}>
                    {email || 'cannot-be-empty'}
                  </div>

                  {firstName && lastName && (
                    <>
                      <div className="profile-grid-span-2">{common('name')}:</div>
                      <div
                        className={`profile-grid-span-8 profile-value profile-value-ellipsis ${isMandatoryData ? 'profile-value-with-margin-right' : ''}`}>{`${firstName} ${lastName}`}</div>
                    </>
                  )}

                  {firstName && !lastName && (
                    <>
                      <div className="profile-grid-span-2">{common('first_name')}:</div>
                      <div className="profile-grid-span-8 profile-value">{firstName}</div>
                    </>
                  )}

                  {!firstName && lastName && (
                    <>
                      <div className="profile-grid-span-2">{common('last_name')}:</div>
                      <div className="profile-grid-span-8 profile-value">{lastName}</div>
                    </>
                  )}

                  {profileSecondaryData}

                  {isMissingProfileData ? (
                    <div className="profile-grid-span-10 profile-page-empty-data-button">
                      {saveProfileModal}
                    </div>
                  ) : null}
                </div>
                <hr />

                <h4 className="profile-page-title-plan">{common('plan')}</h4>

                <div className="profile-grid">
                  <div className="profile-grid-span-2">{common('your_plan')}:</div>
                  <div className="profile-grid-span-2">{` ${plan || common('free')}`}</div>
                  {!hasUsedFreeTrial && (
                    <button
                      style={createBorderRadius(0.28)}
                      onClick={() => dispatch(changePathAction('/shop'))}
                      className="profile-grid-span-3">
                      {common('free_trial')}
                    </button>
                  )}
                  <button
                    style={createBorderRadius(0.68)}
                    onClick={() => dispatch(changePathAction('/shop'))}
                    className={`${hasUsedFreeTrial ? 'profile-grid-span-6' : 'profile-grid-span-3'}`}>
                    {common('shop')}
                  </button>
                </div>
                <hr />

                <h4 className="profile-page-title-social">{common('social')}</h4>
                <div className="profile-page-field-wrapper profile-page-buttons-wrapper">
                  <button
                    style={createBorderRadius(0.98)}
                    onClick={() => dispatch(changePathAction('/achievements'))}>
                    {common('achievements')}
                  </button>
                  <button
                    style={createBorderRadius(0.33)}
                    onClick={() => dispatch(changePathAction('/hall-of-fame'))}>
                    {common('hall_of_fame')}
                  </button>
                </div>

                <hr />
                <h4 className="profile-page-title-actions">{common('actions')}</h4>

                <div className="profile-page-field-wrapper profile-page-buttons-wrapper">
                  {saveProfileModal}
                  <button style={createBorderRadius(0.36)} onClick={this.logoutUser}>
                    {common('log_out')}
                  </button>
                </div>

                <hr />

                <h4 className="profile-page-title-danger-zone">{translate('danger_zone')}</h4>

                <div className="profile-grid">
                  <div className="profile-grid-span-6 warning-text">
                    {translate('danger_zone_warning')}
                  </div>
                  <div className="profile-grid-span-4">
                    <Modal
                      activatorButtonText={common('delete_account')}
                      activatorButtonClass="profile-page-delete-button"
                      style={createBorderRadius(0.26)}>
                      <div
                        className="profile-page-delete-modal-wrapper"
                        style={createBorderRadius(0.26)}>
                        <PageHeader title={translate('delete_modal_title')} />
                        <div className="profile-page-delete-modal-body">
                          <StyledI18n text={translate('delete_modal_description')} />
                          <button
                            className="profile-page-delete-button profile-page-modal-delete-button"
                            style={createBorderRadius(0.86)}
                            onClick={this.deleteUser}>
                            {translate('delete_modal_button_text')}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="profile-page-email-not-confirmed-wrapper">
              <StyledI18n
                text={translate(
                  wasEmailSent ? 'confirm_email_sent_message' : 'confirm_email_warning_message'
                )}
                values={{ email }}
              />
              <button
                className="profile-page-save-button"
                style={createBorderRadius(0.48)}
                onClick={this.sendEmailVerification}>
                {translate(
                  wasEmailSent ? 'confirm_email_again_button_text' : 'confirm_email_button_text'
                )}
              </button>
            </div>
          )}

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />
        </div>

        <Notification message={notificationMessage || notification} />

        {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

        {!isAnnyangSupported && !isPaidUser(user) && (
          <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
        )}

        {path !== '/profile' && <Navigate to={path} replace />}
      </div>
    ) : (
      <>{wasPathChanged(path, 'profile_page') && <Navigate to={path} replace />}</>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(ProfilePage))
