// import { wasPathChanged } from '../router/routerUtil'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import moment from 'moment'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import '../styles/scss/App.scss'
import { auth, db } from '../database/firebase.config'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import NavigateLink from '../components/NavigateLink'
import CheckBox from '../components/CheckBox'
import Notification from '../components/Notification'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import { Input, InputError } from '../components/InputComponents'
import {
  translateFrom,
  common,
  makeWhereIAmText,
  getDatePickerLocale
} from '../lib/languagesUtils/languageUtil'
import { startRecording, stopRecording } from '../lib/speechToTextUtils/speechToText'
import {
  changePathAction,
  updateIsListeningAction,
  setUserAction,
  updateNotificationAction,
  clearNotificationAction
} from '../redux/actions/actions'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import {
  capitalizeWord,
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isLoggedUser,
  isPaidUser,
  isValidEmailFormat,
  createPageCustomNavigationCommands
} from '../lib/methodsUtil'
import { isDevEnvironment, isReadOutLoudDisabled, baseUrl } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'
import { userDataDefaultProperties } from '../lib/signInUpUtil'

// SSS - on pages, Hide sign up link if a user is already logged
// - links to profile should be hidden if user is not logged in

const navigationCommands = ['menu']

const translate = translateFrom('sign_up_page')

const defaultState = {
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  passwordConfirm: '',
  passwordConfirmError: '',
  dob: '',
  isAcceptedTermsAndPolicy: false,
  reCaptchaToken: null,
  reCaptchaError: false
}

class SignUpPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: null,
      isSaving: false,
      ...defaultState
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
    this.checkForEmailError = this.checkForEmailError.bind(this)
    this.checkForPasswordError = this.checkForPasswordError.bind(this)
    this.checkForPasswordConfirmError = this.checkForPasswordConfirmError.bind(this)
    this.toggleAcceptedTermsAndPolicy = this.toggleAcceptedTermsAndPolicy.bind(this)
    this.onCaptchaChange = this.onCaptchaChange.bind(this)
  }

  componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    if (isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    } else {
      dispatch(updateIsListeningAction(false))
      startRecording(user)({ user, dispatch, navigationCommands, lngCode })
      // for the date picker only
      registerLocale(lngCode, getDatePickerLocale(lngCode))
      setDefaultLocale(lngCode)
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleDateChange(date) {
    this.setState({
      // When a data is selected in DatePicker, it is an object but looks like a string; it's a weird format
      // so we convert to a simple one: as such `Apr. 9, 2024` with `en` lngCode as default.
      dob: capitalizeWord(moment(new Date(date)).locale('en').format('MMM D, YYYY'))
    })
  }

  toggleAcceptedTermsAndPolicy() {
    this.setState({ isAcceptedTermsAndPolicy: !this.state.isAcceptedTermsAndPolicy })
  }

  handleClearForm() {
    this.setState(defaultState)
  }

  onCaptchaChange = token => {
    this.setState({ reCaptchaToken: token, reCaptchaError: false })
  }

  async checkForEmailError() {
    const { email } = this.state
    if (!!email.length && !isValidEmailFormat(email)) {
      this.setState({ emailError: 'email_invalid' })
    } else {
      const emailDocRef = doc(db, 'emails', email)
      const emailDoc = await getDoc(emailDocRef)
      this.setState({ emailError: emailDoc.exists() ? 'email_already_registered' : '' })
    }
  }

  checkForPasswordError() {
    const { password, passwordConfirm } = this.state
    this.setState({
      passwordError: password.length > 0 && password.length < 8 ? 'password_too_short' : ''
    })
    if (passwordConfirm) {
      this.setState({
        passwordConfirmError:
          password && passwordConfirm && password === passwordConfirm
            ? ''
            : 'password_confirm_error'
      })
    }
  }

  checkForPasswordConfirmError() {
    const { password, passwordConfirm } = this.state
    this.setState({
      passwordConfirmError:
        password && passwordConfirm && password === passwordConfirm ? '' : 'password_confirm_error'
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { dispatch } = this.props
    const { email, password, dob, reCaptchaToken } = this.state
    this.setState({ isSaving: true })

    // IMPORTANT INFO: I'm using reCAPTCHA v2 (the checkbox version). because This explicitly confirms the user's
    // humanity through interaction and avoids relying on behavioral scoring; unlike reCAPTCHA v3 which assesses
    // user behavior by collecting data such as: Mouse movements, Keyboard inputs, Timing of interactions, etc...
    // it's awesome but not for a voice interaction website
    if (!reCaptchaToken) {
      dispatch(updateNotificationAction(translateFrom('errors')('captcha_error')))
      this.setState({ isSaving: false, reCaptchaError: true })
      return
    }

    try {
      // Create the new user
      const { user } = await createUserWithEmailAndPassword(auth, email, password)
      const userId = user.uid

      // adds the email used (as well as userId) in the 'emails' collection so no one can use it to sign up.
      const emailDocRef = doc(db, 'emails', email)
      await setDoc(emailDocRef, { email, userId })

      const userDocRef = doc(db, 'users', userId)
      const userDoc = await getDoc(userDocRef)

      let userData
      if (!userDoc.exists()) {
        userData = {
          userId,
          ...userDataDefaultProperties,
          email,
          dob
        }

        // adds the default data in the 'users' collection
        await setDoc(userDocRef, userData).then(() => {
          const age = moment().diff(dob, 'years')
          this.setState({ userData, isSaving: false })
          // we remove the userId before updating the user Redux state
          delete userData.userId
          dispatch(setUserAction(userData))
          dispatch(changePathAction('/profile'))
          dispatch(
            updateNotificationAction(
              age < 16 ? 'profile_creation_pending' : 'profile_created_successfully'
            )
          )
        })
      }
    } catch (error) {
      dispatch(
        updateNotificationAction(
          isDevEnvironment && error.message === 'Missing or insufficient permissions.'
            ? 'Permission Denied. Update Firebase Database Rules, likely bigger timestamp'
            : error.message
        )
      )
    } finally {
      setTimeout(() => dispatch(clearNotificationAction()), 6000)
      this.setState({ isSaving: false })
    }
  }

  render() {
    const {
      user,
      path,
      soundLevel,
      language,
      lngCode,
      transcript,
      dispatch,
      isAnnyangSupported,
      notification
    } = this.props
    const {
      isSaving,
      email,
      emailError,
      password,
      passwordError,
      passwordConfirm,
      passwordConfirmError,
      dob,
      reCaptchaError,
      isAcceptedTermsAndPolicy
    } = this.state

    const disableSubmit =
      !email ||
      emailError !== '' ||
      !password ||
      passwordError !== '' ||
      !passwordConfirm ||
      passwordConfirmError !== '' ||
      (email && !isValidEmailFormat(email)) ||
      !dob ||
      !isAcceptedTermsAndPolicy
    const disableClear =
      !email && !password && !passwordConfirm && !dob && !isAcceptedTermsAndPolicy

    const customNavigationCommands = createPageCustomNavigationCommands(navigationCommands)

    const intro = translate('intro')
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? intro : makeWhereIAmText[lngCode]({ title: common('sign_up') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    const commonInputProps = {
      onChange: this.handleInputChange,
      disabled: isSaving
    }

    return (
      <div className="sign-up-page top-navigation">
        <SEO
          description="E=MC² - Embrace the Mission and the call² - Have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} intro={intro} />

          <div className="sign-up-page_form_wrappers">
            <form
              className="form sign-up-page_manual_sign_up_wrapper sign-up-page_to_be_deleted_when_social_on"
              name="sign-up"
              onSubmit={this.handleSubmit}>
              <h2>{translate('create_an_account')}</h2>
              <div className="form-fields">
                <div className="form-column">
                  <div className="form-input-title">{common('email')}:</div>
                  <Input
                    name="email"
                    value={email}
                    {...commonInputProps}
                    type="email"
                    style={createBorderRadius(0.4, true)}
                    onBlur={this.checkForEmailError}
                  />
                  <InputError error={emailError} />

                  <div className="form-input-title">{common('password')}:</div>
                  <Input
                    name="password"
                    value={password}
                    {...commonInputProps}
                    type="password"
                    style={createBorderRadius(0.7)}
                    onBlur={this.checkForPasswordError}
                  />
                  <InputError error={passwordError} />

                  <div className="form-input-title">{common('password_confirm')}:</div>
                  <Input
                    name="passwordConfirm"
                    value={passwordConfirm}
                    {...commonInputProps}
                    type="password"
                    style={createBorderRadius(0.9, true)}
                    onBlur={this.checkForPasswordConfirmError}
                  />
                  <InputError error={passwordConfirmError} />
                </div>
              </div>
              <div className="date-picker-wrapper form-checkbox-wrapper">
                <div className="form-input-title">{common('date_of_birth')}:</div>
                <div className="date-picker-wrapper-container">
                  <DatePicker
                    locale={lngCode}
                    selected={dob || new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                    onChange={this.handleDateChange}
                    minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                    // disabled={isSaving}
                    dateFormat="MMM d, yyyy"
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="sign-up-page_terms-and-policy form-checkbox-wrapper">
                <CheckBox
                  checkedValue={isAcceptedTermsAndPolicy}
                  onChange={isSaving ? () => {} : this.toggleAcceptedTermsAndPolicy}
                  className="form-checkbox"
                  disabled={isSaving}
                  borderRadius={0.7}
                />
                {isAcceptedTermsAndPolicy && <div className="form-checkbox-checked">✔︎</div>}
                <div className="sign-up-page_terms-and-policy-text">
                  <Trans
                    defaults={translate('terms_and_policy_acceptance')}
                    components={{
                      terms: (
                        <a className="form-link" href={`${baseUrl}/terms-of-use`} target="blank">
                          {common('terms')}
                        </a>
                      ),
                      policy: (
                        <a className="form-link" href={`${baseUrl}/privacy-policy`} target="blank">
                          {common('policy')}
                        </a>
                      )
                    }}
                  />
                </div>
              </div>

              {isDevEnvironment && (
                <div
                  className={`sign-up-page_captcha_wrapper ${reCaptchaError ? 'sign-up-page_captcha_wrapper-error' : ''}`}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_DEV_SITE_KEY}
                    onChange={this.onCaptchaChange}
                  />
                </div>
              )}

              <div className="form-buttons">
                <button
                  type="submit"
                  disabled={disableSubmit || isSaving}
                  className={disableSubmit || isSaving ? 'disabled' : ''}
                  style={createBorderRadius(0.9, true)}>
                  {common(isSaving ? 'saving' : 'send')}
                </button>
                <button
                  onClick={this.handleClearForm}
                  disabled={disableClear || isSaving}
                  className={disableClear || isSaving ? 'disabled' : ''}
                  style={createBorderRadius(0.1)}>
                  {common('clear')}
                </button>
              </div>

              <div className="form-buttons sign-to-other-sign-button-wrapper space-between">
                <div className="sign-to-other-sign-message">{translate('already_account')}</div>
                <NavigateLink
                  dispatch={dispatch}
                  navigateUrl="/sign-in"
                  navigateText={common('sign_in')}
                />
              </div>
            </form>
          </div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}
        </div>

        <Notification message={notification} />

        {path !== '/sign-up' && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(SignUpPage))
