import React from 'react'
import StyledI18n from '../components/StyledI18n'
import PageHeader from '../components/PageHeader'
import { createBorderRadius } from '../lib/methodsUtil'
import { translateFrom } from '../lib/languagesUtils/languageUtil'

// SSS - COPPA (U.S. Users): For users under 13 in the U.S., you must comply with COPPA and require parental consent for data collection.

// SSS - GDPR
// I'm over 18 or my mummy or daddy allow me to use this site for educational purposes
// During sign-up, include a required field for the user's date of birth:

const translate = translateFrom('privacy_policy_page')

const PrivacyPolicyPage = () => (
  <div className="privacy-policy-page top-navigation">
    <div className="navigation-box" style={createBorderRadius(0.5, true)}>
      <PageHeader title={translate('title')} intro={translate('intro')} />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
        <div className="policy-section" key={n}>
          <strong>{translate(`sections.${n}.title`)}</strong>
          <StyledI18n text={translate(`sections.${n}.text`)} />
        </div>
      ))}
      <hr />
      <div>{translate('outro')}</div>
    </div>
  </div>
)

export default PrivacyPolicyPage
