import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { isChrome } from 'react-device-detect'
import '../styles/scss/App.scss'
import {
  startRecording,
  stopRecording,
  isUsingAnnyang
} from '../lib/speechToTextUtils/speechToText'
import {
  createMainNavigationCommands,
  createExtendedNavigationCommands
} from '../lib/speechToTextUtils/speechToTextMethodsUtil'
import {
  translateFrom,
  common,
  instructionTranslate,
  makeWhereIAmText
} from '../lib/languagesUtils/languageUtil'
import { isReadOutLoudDisabled } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'
import {
  isLearnGeographyPage,
  isLearnMathematicsPage,
  isRedirectButtonClick,
  isRedirectPath,
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isDivisionPath,
  getDivisionPageTitle,
  isTranscriptAMatch,
  capitalizePagename,
  removeComingSoonTopics,
  updateNavigationCommandsWithMenu,
  isPaidUser,
  getIsOverOneDayShare,
  isNeedDonationPath
} from '../lib/methodsUtil'
// import { temporarilyUnlockTopics } from '../lib/dataUtil'
import PageHeader from './PageHeader'
import SEO from './SEO'
import SpeechButton from './SpeechButton'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import NavigationButtons from './NavigationButtons'
import ReadOutLoudRoboticVoiceNotice from './ReadOutLoudRoboticVoiceNotice'
import FreeVersionBanner from './FreeVersionBanner'
import Notification from './Notification'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction } from '../redux/actions/actions'
import { saveTranscriptAction } from '../redux/actions/actions'
import { wasPathChanged } from '../router/routerUtil'

// the reason for this is because for example learn additions, subtractions, etc... use TopicNavigationPage instead of LearnPage
// but share similar intro and instructions (but different titles) so if it's one of these pages, we will use learn_mathematics_page
// as all the languages used for these 4 pages is written only once in en.json / same for geograohy and special divisions page with all
// the quotient and remainders logic for each level(beginner/easy/medium/hard)
const getUpdatedPageName = pageName =>
  isLearnGeographyPage(pageName)
    ? 'learn_geography_page'
    : pageName.indexOf('level_divisions_page') > -1
      ? 'level_divisions_page'
      : isLearnMathematicsPage(pageName)
        ? 'learn_mathematics_page'
        : pageName

const isLearnPageBackToInstructionCommand = command =>
  [
    'additions',
    'subtractions',
    'multiplications',
    'divisions',
    'mathematics',
    'capitals',
    'flags',
    'locations',
    'country_select',
    'geography'
  ].includes(command)

class TopicNavigationPage extends Component {
  constructor(props) {
    super(props)
    const { isChrome, isUsingMicrophone, soundLevel } = props
    this.state = {
      wasHelpOrWhereAmITriggeredAtLeastOnce: false,
      isRoboticVoice: !isChrome && isUsingMicrophone && soundLevel !== 'off'
    }
  }

  componentDidMount() {
    const {
      dispatch,
      user,
      path,
      lngCode,
      pageName,
      navigationCommands = [],
      temporarilyUnlocked
    } = this.props
    if (isNeedDonationPath(path)) {
      dispatch(changePathAction('/support'))
    } else {
      const updatedNavCommands = updateNavigationCommandsWithMenu(navigationCommands, pageName)
      startRecording(user)({
        user,
        dispatch,
        navigationCommands: updatedNavCommands,
        lngCode,
        temporarilyUnlocked
      })
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      user,
      pageName,
      language,
      lngCode,
      gender,
      soundLevel,
      isListening,
      isRoboticVoiceNoticeVisible,
      temporarilyUnlocked
    } = this.props
    const {
      transcript: nextTranscript,
      path: nextPath,
      navigationCommands = [],
      contentCommands = [],
      language: nextLanguage,
      gender: nextGender,
      soundLevel: nextSoundLevel,
      isListening: nextIsListening
    } = nextProps
    const { isRoboticVoice } = this.state

    const updatedNavCommands = updateNavigationCommandsWithMenu(navigationCommands, pageName)
    const allAllowedWords = [
      ...Object.keys(
        createExtendedNavigationCommands({
          user,
          navigationCommands: updatedNavCommands,
          lngCode,
          temporarilyUnlocked
        })
      ),
      ...contentCommands
    ]
    const isLanguageButtonClick = language !== nextLanguage // LanguagePage
    const isSettingsButtonClick = gender !== nextGender || soundLevel !== nextSoundLevel // SettingsPage

    return (
      isTranscriptAMatch(nextTranscript, allAllowedWords) ||
      isRedirectButtonClick(nextPath, removeComingSoonTopics(updatedNavCommands)) ||
      isRedirectPath(nextPath) ||
      isLanguageButtonClick ||
      isSettingsButtonClick ||
      isHelpRequest(nextTranscript) ||
      isWhereAmIRequest(nextTranscript, lngCode) ||
      (isRoboticVoice && isRoboticVoiceNoticeVisible && isListening !== nextIsListening)
    )
  }

  componentDidUpdate(prevProps) {
    const { isListening: prevIsListening, transcript: prevTranscript } = prevProps
    const {
      user,
      dispatch,
      lngCode,
      pageName,
      transcript,
      navigationCommands = [],
      isRoboticVoiceNoticeVisible,
      isListening,
      temporarilyUnlocked
    } = this.props
    const { isRoboticVoice } = this.state

    // The below is to prevent a weird bug on the questions page only. SpeechButton needs 'help' to NOT be reset
    // so it resets the count itself internally. But the question page needs it reset at the right place
    // to avoid helping on questions links clicks
    if (
      (isHelpRequest(transcript) || isWhereAmIRequest(transcript, lngCode)) &&
      pageName === 'questions_page'
    ) {
      dispatch(saveTranscriptAction([]))
    }

    if (prevTranscript !== transcript) {
      const updatedNavCommands = updateNavigationCommandsWithMenu(navigationCommands, pageName)
      const customNavigationCommands = createExtendedNavigationCommands({
        user,
        navigationCommands: updatedNavCommands,
        lngCode,
        temporarilyUnlocked
      })
      const foundCommand = Object.keys(customNavigationCommands).find(navCommand =>
        transcript.map(ts => ts.toLowerCase()).includes(navCommand)
      )
      if (foundCommand) {
        dispatch(changePathAction(customNavigationCommands[foundCommand]))
      }
    }

    if (isRoboticVoice && isRoboticVoiceNoticeVisible && prevIsListening && !isListening) {
      this.setState({ wasHelpOrWhereAmITriggeredAtLeastOnce: true })
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  render() {
    const {
      user,
      path,
      dispatch,
      pageName,
      navigationCommands = [],
      extraTopicsInstructions = [],
      topicNavigationPath,
      children,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel,
      temporarilyUnlocked,
      defaultStyle,
      borderRadius = 0.5,
      disableSpeechButton = false,
      contentCommands,
      extraIntro,
      SEODescription,
      canonicalPath,
      pageNeedsKeyboardTyping = false,
      titleOnly = false,
      isAnnyangSupported,
      isUsingMicrophone,
      isRoboticVoiceNoticeVisible,
      showScrollbar = false,
      setShowScrollbar = () => {},
      notification
    } = this.props
    const { wasHelpOrWhereAmITriggeredAtLeastOnce } = this.state

    const isLearnGeographyOrMathematicsPage =
      isLearnGeographyPage(pageName) || isLearnMathematicsPage(pageName)
    const updatedPageName = getUpdatedPageName(pageName)
    const translate = translateFrom(updatedPageName)
    let intro = extraIntro ? `${translate('intro')} ${extraIntro}` : translate('intro')

    const updatedNavCommands = updateNavigationCommandsWithMenu(navigationCommands, pageName)
    const updatedNavigationCommands = isLearnGeographyOrMathematicsPage
      ? updatedNavCommands.map(command =>
          isLearnPageBackToInstructionCommand(command) ? `back_to_${command}` : command
        )
      : updatedNavCommands

    const instructions = isUsingMicrophone
      ? [...extraTopicsInstructions, ...updatedNavigationCommands].map(command =>
          instructionTranslate(command)
        )
      : []

    const text = `${intro} ${instructions.join('. ')}`

    // LATER - This logic is only to get the title and is very complicated - topicNavigationPath is also only used here
    // this can probably be refactored to do something way simpler!
    const title = isLearnGeographyOrMathematicsPage
      ? common(pageName.replace(/_page/g, ''))
      : topicNavigationPath && isDivisionPath(topicNavigationPath)
        ? translateFrom(getDivisionPageTitle(topicNavigationPath))('title')
        : translate('title')

    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp
          ? text
          : makeWhereIAmText[lngCode]({ title, actsAsLearnMenu: pageName.indexOf('learn_') === 0 }),
        dispatch,
        language,
        gender,
        clearTranscriptOnEnd: true,
        navigationCommands: updatedNavigationCommands,
        user
      })
    }

    const pageClassName = isLearnGeographyOrMathematicsPage ? pageName : updatedPageName

    return (
      <div
        className={`${pageClassName.replace(/_/g, '-')} top-navigation ${showScrollbar && pageName === 'support_page' ? 'support-page-navigation-box' : ''}`}>
        <SEO
          title={`Mini Einstein - ${capitalizePagename(pageName)}`}
          description={SEODescription}
          path={canonicalPath || path}
        />

        <div
          className="navigation-box"
          style={defaultStyle || createBorderRadius(borderRadius, true)}>
          <PageHeader
            title={title}
            intro={intro}
            instructions={instructions}
            titleOnly={titleOnly}
          />

          {children && children(translate, setShowScrollbar, isChrome, dispatch)}

          {!pageNeedsKeyboardTyping && <AppListeningSpeakingVisual />}

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}

          {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

          {wasHelpOrWhereAmITriggeredAtLeastOnce && isRoboticVoiceNoticeVisible && (
            <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
          )}

          <NavigationButtons
            user={user}
            pageName={pageName}
            lngCode={lngCode}
            customNavigationCommands={createMainNavigationCommands({
              user,
              navigationCommands,
              lngCode,
              withComingSoon: true,
              temporarilyUnlocked
            })}
            isOverOneDayShare={getIsOverOneDayShare(temporarilyUnlocked)}
            dispatch={dispatch}
          />
        </div>

        {!isNavigationSoundOff && !pageNeedsKeyboardTyping && (
          <SpeechButton
            className={`${updatedPageName.replace(/_/g, '-')}-speech-button`}
            text={text}
            disableSpeechButton={disableSpeechButton}
            forceSpeechButtonReset={isTranscriptAMatch(transcript, contentCommands)}
            navigationCommands={updatedNavigationCommands}
          />
        )}

        <Notification message={notification} />

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(TopicNavigationPage)
