/* eslint-disable */
const isDevEnvironment = process.env.NODE_ENV === 'development'

const baseUrl = process.env.PUBLIC_URL
/* eslint-enable */

const mathsNumbersUrls = `${baseUrl}/images/mathematics/numbers/`

// it seems to run twice in dev, but only once in Prod so we get previous answer from -3 in dev -2 in prod
const minusIndexNumber = isDevEnvironment ? 3 : 2

// ------------------------------------------------------------------------
// DEVELPOMENT ENVIRONMENT VARIABLES SET-UP
// Read out loud disabled in Dev
const isReadOutLoudDisabled = false // Disabled by default in Dev / Set to false to hear all spoken

// Still needs a paid user to be logged in on top of being true
const blockGoogleSpeechToTextAPI = true // Set to true to allow GoogleSpeechToTextAPI (💰)

const unlockAllTopics = isDevEnvironment // set to false to lock all Topics like in Prod

// ------------------------------------------------------------------------

// Change manually when we want to show it: YYYY-MM (January is 01)
const lastUpdatedDate = new Date('2024-12')

export {
  isDevEnvironment,
  baseUrl,
  mathsNumbersUrls,
  minusIndexNumber,
  isReadOutLoudDisabled,
  blockGoogleSpeechToTextAPI,
  unlockAllTopics,
  lastUpdatedDate
}
