// Unlike routerUtil, this file is to set SPOKEN command to route redirect

const mainCommandpathMap = {
  en: {
    about: '/about',
    achievements: '/achievements',
    contact: '/contact',
    'hall of fame': '/hall-of-fame',
    language: '/language',
    menu: '/menu',
    questions: '/questions',
    settings: '/settings',
    shop: '/shop',
    // SSS - Test sign in and sign up vocal commands
    'sign in': '/sign-in',
    'sign up': '/sign-up',
    support: '/support',

    // MATHEMATICS
    mathematics: '/mathematics',
    additions: '/mathematics/additions',
    subtractions: '/mathematics/subtractions',
    multiplications: '/mathematics/multiplications',
    divisions: '/mathematics/divisions',

    // WORDS
    words: '/words',
    colors: '/words/colors',
    'letters and numbers': '/words/letters-and-numbers',
    animals: '/words/animals',
    food: '/words/food',
    'other words': '/words/other-words',

    // GEOGRAPHY
    geography: '/geography',
    capitals: '/geography/capitals',
    flags: '/geography/flags',
    locations: '/geography/locations',
    'country select': '/geography/country-select',

    // READING
    reading: '/reading',
    'read words': '/reading/read-words',
    'read sentences': '/reading/read-sentences',
    'read stories': '/reading/read-stories'
  },

  fr: {
    // CAREFULL ! Need to follow same order as en
    'à propos': '/about',
    médailles: '/achievements',
    contact: '/contact',
    'temple de la renommée': '/hall-of-fame',
    langue: '/language',
    menu: '/menu',
    questions: '/questions',
    paramètres: '/settings',
    boutique: '/shop',
    'se connecter': '/sign-in',
    "s'inscrire": '/sign-up',
    soutien: '/support',

    // MATHEMATICS
    mathématiques: '/mathematics',
    additions: '/mathematics/additions',
    soustractions: '/mathematics/subtractions',
    multiplications: '/mathematics/multiplications',
    divisions: '/mathematics/divisions',

    // WORDS
    'les mots': '/words',
    couleurs: '/words/colors',
    'chiffres et lettres': '/words/letters-and-numbers',
    animaux: '/words/animals',
    nourriture: '/words/food',
    'autres mots': '/words/other-words',

    // GEOGRAPHY
    géographie: '/geography',
    capitales: '/geography/capitals',
    drapeaux: '/geography/flags',
    localisations: '/geography/locations',
    'choisir un pays': '/geography/country-select',

    // READING
    lecture: '/reading',
    'lire des mots': '/reading/read-words',
    'lire des phrases': '/reading/read-sentences',
    'lire des histoires': '/reading/read-stories'
  }
}

// sub commands translations
const learn = { en: 'learn', fr: 'apprendre' }
const play = { en: 'play', fr: 'jouer' }
const beginner = { en: 'beginner', fr: 'débutant' }
const easy = { en: 'easy', fr: 'facile' }
const medium = { en: 'medium', fr: 'moyen' }
const hard = { en: 'hard', fr: 'difficile' }
const quotient = { en: 'quotient', fr: 'quotient' }
const remainder = { en: 'remainder', fr: 'reste' }
const reverse = { en: 'reverse', fr: 'inversé' }
const famousOnes = { en: 'famous ones', fr: 'les plus connues' }
const others = { en: 'others', fr: 'les autres' }

const createWordCommandPathMap = (name, lngCode = 'en') => ({
  [`/words/${name}`]: {
    [learn[lngCode]]: `/words/${name}/learn`,
    [play[lngCode]]: `/words/${name}/play`
  }
})

const createMathematicsCommandPathMap = (name, lngCode = 'en') => ({
  [`/mathematics/${name}`]: {
    [learn[lngCode]]: `/mathematics/${name}/learn`,
    [beginner[lngCode]]: `/mathematics/${name}/beginner`,
    [easy[lngCode]]: `/mathematics/${name}/easy`,
    [medium[lngCode]]: `/mathematics/${name}/medium`,
    [hard[lngCode]]: `/mathematics/${name}/hard`
  }
})

const createLearnMathematicsCommandPathMap = (name, lngCode = 'en') => ({
  [`/mathematics/${name}/learn`]: {
    [beginner[lngCode]]: `/mathematics/${name}/learn/beginner`,
    [easy[lngCode]]: `/mathematics/${name}/learn/easy`,
    [medium[lngCode]]: `/mathematics/${name}/learn/medium`
  }
})

const createLearnGeographyCommandPathMap = (name, lngCode = 'en') => ({
  [`/geography/${name}/learn`]: {
    [famousOnes[lngCode]]: `/geography/${name}/learn/famous-ones`,
    [others[lngCode]]: `/geography/${name}/learn/others`
  }
})

const createDivisionQuotientRemainderCommandPathMap = (level, lngCode = 'en') => ({
  [`/mathematics/divisions/${level}`]: {
    [quotient[lngCode]]: `/mathematics/divisions/${level}/quotient`,
    [remainder[lngCode]]: `/mathematics/divisions/${level}/remainder`
  }
})

const createLearnEasyMediumHardCommandPathMap = (topic, name, lngCode = 'en') => {
  const obj = {
    [easy[lngCode]]: `/${topic}/${name}/easy`,
    [medium[lngCode]]: `/${topic}/${name}/medium`,
    [hard[lngCode]]: `/${topic}/${name}/hard`
  }

  if (name.indexOf('reverse') === -1) {
    obj[learn[lngCode]] = `/${topic}/${name}/learn`

    if (name.indexOf('capitals') > -1) {
      obj[reverse[lngCode]] = `/${topic}/${name}/reverse`
    }
  }

  return {
    [`/${topic}/${name}`]: obj
  }
}

// for sub-menu (repeated words)
const extendedCommandPathMap = {
  en: {
    // WORDS
    ...createWordCommandPathMap('colors'),
    ...createWordCommandPathMap('animals'),
    ...createWordCommandPathMap('food'),
    ...createWordCommandPathMap('letters-and-numbers'),

    // MATHEMATICS
    ...createMathematicsCommandPathMap('additions'),
    ...createLearnMathematicsCommandPathMap('additions'),
    ...createMathematicsCommandPathMap('subtractions'),
    ...createLearnMathematicsCommandPathMap('subtractions'),
    ...createMathematicsCommandPathMap('multiplications'),
    ...createLearnMathematicsCommandPathMap('multiplications'),
    ...createMathematicsCommandPathMap('divisions'),
    ...createLearnMathematicsCommandPathMap('divisions'),
    ...createDivisionQuotientRemainderCommandPathMap('beginner'),
    ...createDivisionQuotientRemainderCommandPathMap('easy'),
    ...createDivisionQuotientRemainderCommandPathMap('medium'),
    ...createDivisionQuotientRemainderCommandPathMap('hard'),

    // GEOGRAPHY
    ...createLearnEasyMediumHardCommandPathMap('geography', 'capitals'),
    ...createLearnGeographyCommandPathMap('capitals'),
    ...createLearnEasyMediumHardCommandPathMap('geography', 'capitals/reverse'),
    ...createLearnEasyMediumHardCommandPathMap('geography', 'flags'),
    ...createLearnGeographyCommandPathMap('flags'),
    ...createLearnEasyMediumHardCommandPathMap('geography', 'locations'),
    ...createLearnGeographyCommandPathMap('locations'),

    // READING
    ...createLearnEasyMediumHardCommandPathMap('reading', 'read-words'),
    ...createLearnEasyMediumHardCommandPathMap('reading', 'read-sentences'),
    ...createLearnEasyMediumHardCommandPathMap('reading', 'read-stories')
  },

  fr: {
    // WORDS
    ...createWordCommandPathMap('colors', 'fr'),
    ...createWordCommandPathMap('animals', 'fr'),
    ...createWordCommandPathMap('food', 'fr'),
    ...createWordCommandPathMap('letters-and-numbers', 'fr'),

    // MATHEMATICS
    ...createMathematicsCommandPathMap('additions', 'fr'),
    ...createLearnMathematicsCommandPathMap('additions', 'fr'),
    ...createMathematicsCommandPathMap('subtractions', 'fr'),
    ...createLearnMathematicsCommandPathMap('subtractions', 'fr'),
    ...createMathematicsCommandPathMap('multiplications', 'fr'),
    ...createLearnMathematicsCommandPathMap('multiplications', 'fr'),
    ...createMathematicsCommandPathMap('divisions', 'fr'),
    ...createLearnMathematicsCommandPathMap('divisions', 'fr'),
    ...createDivisionQuotientRemainderCommandPathMap('beginner', 'fr'),
    ...createDivisionQuotientRemainderCommandPathMap('easy', 'fr'),
    ...createDivisionQuotientRemainderCommandPathMap('medium', 'fr'),
    ...createDivisionQuotientRemainderCommandPathMap('hard', 'fr'),

    // GEOGRAPHY
    ...createLearnEasyMediumHardCommandPathMap('geography', 'capitals', 'fr'),
    ...createLearnGeographyCommandPathMap('capitals', 'fr'),
    ...createLearnEasyMediumHardCommandPathMap('geography', 'capitals/reverse', 'fr'),
    ...createLearnEasyMediumHardCommandPathMap('geography', 'flags', 'fr'),
    ...createLearnGeographyCommandPathMap('flags', 'fr'),
    ...createLearnEasyMediumHardCommandPathMap('geography', 'locations', 'fr'),
    ...createLearnGeographyCommandPathMap('locations', 'fr'),

    // READING
    ...createLearnEasyMediumHardCommandPathMap('reading', 'read-words', 'fr'),
    ...createLearnEasyMediumHardCommandPathMap('reading', 'read-sentences', 'fr'),
    ...createLearnEasyMediumHardCommandPathMap('reading', 'read-stories', 'fr')
  }
}

const getLngCommandKeys = lngCode => {
  const otherCommands = [
    learn,
    play,
    beginner,
    easy,
    medium,
    hard,
    quotient,
    remainder,
    reverse,
    famousOnes,
    others
  ].map(obj => obj[lngCode])

  return [...Object.keys(mainCommandpathMap[lngCode]), ...otherCommands]
}

export { mainCommandpathMap, extendedCommandPathMap, getLngCommandKeys }
