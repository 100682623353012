import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { baseUrl } from '../lib/_processUtil'
import { isNeedDonationPath } from '../lib/methodsUtil'
import { changePathAction } from '../redux/actions/actions'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { wasPathChanged } from '../router/routerUtil'

// birthdate from database "users" collection, is written as such with moment:
// with dob being the data from firebase, it gets nicely displayed as such:
// the moment(dob).locale(lngCode).format('LL')

const pageName = 'hall_of_fame_page'

class HallOfFamePage extends Component {
  componentDidMount() {
    const { path, dispatch } = this.props
    if (isNeedDonationPath(path)) {
      dispatch(changePathAction('/support'))
    }
  }

  render() {
    const { path } = this.props

    return (
      <div className="hall-of-fame-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '48px',
            height: '100%'
          }}>
          Hall of fame Page
          <div>
            {['gold', 'silver', 'bronze'].map(medalColor => (
              <ImageWithDimensions
                key={medalColor}
                alt="start icon"
                className="menu-button-icon"
                src={`${baseUrl}/images/hall-of-fame/${medalColor}-einstein.webp`}
              />
            ))}
          </div>
        </div>

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(HallOfFamePage)
