import React from 'react'
import StyledI18n from '../components/StyledI18n'
import PageHeader from '../components/PageHeader'
import { createBorderRadius } from '../lib/methodsUtil'
import { translateFrom } from '../lib/languagesUtils/languageUtil'

const translate = translateFrom('terms_of_use_page')

const TermsOfUsePage = () => (
  <div className="terms-of-use-page top-navigation">
    <div className="navigation-box" style={createBorderRadius(0.5, true)}>
      <PageHeader title={translate('title')} intro={translate('intro')} />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(n => (
        <div className="terms-section" key={n}>
          <strong>{translate(`sections.${n}.title`)}</strong>
          <StyledI18n text={translate(`sections.${n}.text`)} />
        </div>
      ))}
      <hr />
      <div>{translate('outro')}</div>
    </div>
  </div>
)

export default TermsOfUsePage
